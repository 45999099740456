
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.humanIcon {
  border-radius: 50%;
  background: $ads-light-blue;
  color: $ads-navy;
  min-width: 46px;
  height: 46px;
}

.SreText {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: $color-primary;
  padding-left: 11px;
}

.addNewBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.noSreOption {
  box-shadow: 0px 1px 4px $ads-light-40;
  border-radius: 4px;
  padding: 32px 0 38px 54px;
  margin-top: 2.5rem;
}

.noSreOptionWrapper,
.sre-option-wrapper,
.addNewBtn {
  max-width: 808px;
}

.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
