
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  color: $ads-navy;
  margin-bottom: 0.8rem;
}

::v-deep a {
  font-weight: 700;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5;
  color: $ads-navy;
  margin-bottom: 0.625rem;

  &:last-child {
    margin-bottom: 0;
  }
}
.school-finder {
  &--card {
    padding: 1.5rem 1.5rem 2rem 1.5rem;

    ::v-deep .preference-list li {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__title {
    font-size: 1.25rem;
    color: $ads-navy;
    font-weight: bold;
  }

  &__more-info {
    font-weight: bold !important;
    font-size: 1rem;
  }

  &__description {
    font-size: 1rem;
    font-weight: 500;
    color: $ads-dark;
  }

  &__search-wrapper {
    display: flex;
  }

  &__search {
    flex: 1;
    margin-right: 1rem;

    & ::v-deep input {
      font-size: 1.125rem;
    }

    ::v-deep .v-input__slot {
      padding-bottom: 0.5rem;
    }

    ::v-deep .v-input__icon--prepend-inner {
      margin-right: 0.5rem;
    }

    & ::v-deep .error--text input {
      color: $ads-error-red;
    }
  }

  &__search-button.btn-search.v-btn {
    margin-top: 0.3rem;
    padding: 0 1rem;
  }
  &__search-button.btn-search.v-btn.v-btn--disabled {
    border: 3px solid transparent;
  }
}

.more-info__icon {
  display: inline;
  font-size: 1.5rem;
  text-decoration: none;
  margin-right: 0.5rem;
}

.search-button__icon {
  margin-right: 0.5rem;
}

.nominated-schools {
  // These are tricks to make the transition smoother
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  padding-top: 2rem;

  &__title {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 0;
  }

  &__description {
    font-size: 1rem;
    color: $ads-dark-60;
  }
}

// Override vuetify flipping the icon when input is focused
::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

.error-box {
  margin-top: 2rem;
}

.error--text {
  font-weight: normal;
  font-size: 1rem;
}

.message--error ::v-deep .error-box__icon {
  color: $ads-red;
}

.message--warning ::v-deep .error-box__icon {
  color: $ads-warning-orange;
}

.expand-transition-enter-active,
.expand-transition-leave-active {
  transition-property: all;
}

.expand-transition-leave-to {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
