
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.humanIcon {
  background: $ads-light-blue;
  color: $ads-navy;
}

.heading-text {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: $color-primary;
  padding-left: 11px;
}

.form-width--808 {
  max-width: 808px;
}

.text-16-24 {
  font-size: 16px;
  line-height: 24px;
}
