
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.fullWidth {
  width: 100%;
}
.icon {
  background-color: $color-selected-item;
  width: 1.5em;
  line-height: 1.5em;
}
.addressDetails {
  background-color: $grey-light-10;
}
.chip {
  font-weight: bold;
}
.cancelButton {
  border: none;
}
.confirmModal {
  padding: 1em;
  .header {
    font-size: 1.25rem;
  }
  .footer {
    text-align: right;
  }
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}
.modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
  color: $grey-darken1 !important;
  &:focus {
    color: $color-primary !important;
  }
}
