
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-input label.v-label {
  color: $ads-navy;
}
::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
  border: 2px solid $ads-navy;
  background: none !important;
}
::v-deep .v-input--selection-controls__input {
  .v-icon {
    color: $grey-darken1;
  }
  .v-input--selection-controls__ripple:before {
    opacity: 0;
  }
}
::v-deep .v-autocomplete .v-icon.v-icon--link:focus {
  border: 3px solid $color-primary;
  border-radius: 50%;
}
::v-deep .error-message-bold {
  color: $color-error-dark;
  font-weight: bold;
}
::v-deep .theme--light.v-icon:focus::after {
  opacity: 0;
}
::v-deep .v-autocomplete .v-icon.v-icon--link.error--text:focus {
  border: 3px solid $color-error-dark;
  border-radius: 50%;
}
.address-options {
  margin: auto;
  width: 70%;
  color: $ads-navy;

  .address-options-box {
    font-family: $digitize-eoi-font-family;
    background-color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    cursor: default;
    .address-options-label {
      font-size: 22px;
      font-weight: bold !important;
      line-height: 26px;
      cursor: pointer;
    }
  }
  .existing-address {
    background-color: $grey-light-10;
    height: 79px;
    border-radius: 4px;

    .ern-icon {
      width: 55px;
      height: 23px;
      background: white;
      border: 1px solid $color-dark70;
      box-sizing: border-box;
      border-radius: 20px;
      font-size: 13px;
      color: $color-dark70;
      font-weight: 700;
      font-family: Arial;
    }
    .ern-address {
      font-family: Arial;
    }
  }
  .text-or {
    text-align: center !important;
    font-weight: 700;
    line-height: 26px;
    font-size: 20px;
    cursor: default;
  }
  .warning-icon {
    color: $color-warning-dark !important;
  }
  .v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .v-btn:not(.v-btn--round):not(.v-btn--outlined):not(.v-btn--text) {
    font-family: $digitize-eoi-font-family;
  }
  .error-message {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-error-dark;
  }
  .note-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .paddingText {
    padding-top: 1px;
    padding-left: 3px;
    width: 95%;
  }
  .no-cursor {
    cursor: default;
  }
}
