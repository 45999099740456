
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .contactCard {
  background: #fdfdfd;
  .v-input .v-input__control .v-input__slot {
    background: white;
  }
}
