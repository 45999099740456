
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.radio-group {
  ::v-deep label.v-label {
    font-weight: normal;
    color: $ads-navy;
  }
  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      //   color: $ads-selection-control-gray;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
  }
}
