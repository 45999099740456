
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.forward-back-nav {
  display: flex;
  justify-content: space-between;
  margin: 2.25rem 0;
  font-family: $digitize-eoi-font-family;
}

.button--forward:first-child {
  margin-left: auto;
}

.button {
  font-size: 1rem;
  font-weight: 500;

  &__icon {
    margin-right: 0.75rem;
    margin-left: -0.25rem;
  }

  &__spinner {
    margin-right: 0.75rem;
  }
}
.button--forward.v-btn--disabled {
  border: 3px solid transparent;
}
.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
