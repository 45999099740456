
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.app-settings__header {
  background: $ads-navy;
  color: white !important;
  position: sticky; // sticky allows keyboard scroll when focused in the header
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 64px;
  z-index: 6;
  h1 {
    font-size: 1.25rem;
  }
  .v-btn {
    &:focus {
      border: 2px solid $color-focus !important;
    }
  }
}

.app-settings__header-ie {
  position: fixed; // ie-11 doesn't support sticky
}

.app-settings__content {
  color: $ads-navy;
  background: $ads-light-10;
  width: 100%;
  min-height: calc(100vh - 64px);
  position: absolute;
  top: 64px;
  h2 {
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.875rem;
    margin-bottom: 0px;
  }
  p.subtitle {
    font-size: 1rem;
    color: $ads-navy;
  }
  .app-settings__circle-icon {
    border-radius: 50%;
    background: $ads-light-blue;
    color: $ads-navy;
    min-width: 54px;
    height: 54px;
    &.large {
      min-width: 116px;
      height: 116px;
    }
  }
  .app-settings__last-updated {
    font-style: italic;
    color: $ads-dark-70;
    font-size: 0.875rem;
    .v-icon {
      color: $ads-dark-70;
    }
  }
  .app-settings-heading {
    font-size: 1.25rem;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__subheading {
    font-size: 1rem;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__paragraph-text {
    max-width: 30rem;
  }
  .full-width {
    width: 100%;
    height: 39px;
    button {
      float: right;
    }
  }
  .sr-only {
    background-color: transparent;
  }
  .v-tabs {
    .v-tab {
      font-size: 18px;
      margin-bottom: 20px;
      letter-spacing: normal;
      padding-left: 60px;
      width: 292px;
      height: 67px;

      span {
        z-index: 1;
      }
    }
    .v-tabs-bar,
    .v-tabs-items {
      background: $ads-light-10;
    }
    .v-tab--active {
      background: $ads-white;
    }
  }
}

.v-application
  > .v-dialog__content
  > .v-dialog.v-dialog--active
  .v-card
  > .v-card__title
  > .title {
  font-size: 1.25rem !important;
}
.theme--light.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before,
.theme--light.v-btn:focus::before {
  opacity: 0;
}
.theme--light.v-btn.settingsPrimary.primary:not(.v-btn--text):not(
    .v-btn--outlined
  ):focus {
  box-shadow: 0 0 0 4px $color-focus;
}
.theme--light.v-btn.settingsOutline.v-btn--outlined:focus {
  box-shadow: 0 0 0 4px $color-primary !important;
}
.v-list-item__title .v-input--checkbox.criteria .v-messages {
  display: none;
}
.v-alert__content .settings-link {
  margin: 0;
  text-decoration: underline;
  padding: 0 !important;
  height: 20px;
}

.settingsIcon.theme--dark.v-btn.v-btn--disabled {
  color: $color-btn-disabled-text-default !important;
  opacity: 1;
}

#adsDialogComponent .title {
  font-weight: bold;
  font-size: 28px !important;
  line-height: 34px;
  padding-top: 10px;
}

#adsDialogComponent .close-btn__wrapper .v-btn {
  border: none;

  &:focus {
    border: 2px solid $color-primary;
  }
}
.SREWrapper {
  max-width: 1360px;
  > div {
    &:not(:first-child) {
      margin-top: 1.5rem;
    }
    &:not(.v-alert) {
      padding: 27px 34px 40px;
    }
    margin-left: 9em;
    margin-right: 9em;
  }
}
.saveCloseBtn {
  display: flex;
  justify-content: flex-end;
  margin-right: 8.5rem;
}

.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
