
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.theme--light.v-icon.v-icon--link:focus {
  border: 3px solid $color-primary;
  border-radius: 50%;
}
.theme--light.v-icon.v-icon--link.error--text:focus {
  border: 3px solid $color-error-dark;
  border-radius: 50%;
}
.theme--light.v-icon:focus::after {
  opacity: 0;
}
.container.adsBanner {
  font-family: $digitize-eoi-font-family;
  margin: auto;
  .appTitle {
    font-size: 51px;
  }
  .appSubtitle {
    font-size: 22px;
    font-weight: normal;
  }
  .d-flex.align-start {
    align-items: center !important;
  }
}
.pnrForm {
  font-family: $digitize-eoi-font-family;
  .v-form {
    .v-text-field.v-input {
      max-width: 500px !important;
    }
  }
  .container {
    margin: 0 auto;
    width: 70%;
  }
  .section-heading {
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    font-style: normal;
    color: $ads-navy;
  }
  .v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .normal-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }
  .normal-text-medium-size {
    font-size: 22px;
  }
  .content-img.rightSpacing {
    margin-top: -20px;
  }
}
