
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-icon.v-icon svg {
  border-radius: 0;
  fill: currentColor;
}

.v-avatar .v-icon svg {
  margin-left: -50%;
  width: 45px;
  height: 46px;
  transform: translate(50%, 0px);
}
