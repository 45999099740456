
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-data-table.dataTable {
  background: transparent;
  tr {
    vertical-align: top;
  }
  tr:hover {
    background: transparent !important;
  }
  th {
    color: $ads-dark-70 !important;
    font-size: 16px !important;
  }
  td {
    color: $grey-1 !important;
    font-size: 0.875rem !important;
    padding-top: 15px;
  }
}
::v-deep .ApplicationStatusChips {
  width: 100%;
  font-weight: 700;
  .v-chip__content span,
  .v-chip__content {
    width: 100%;
    text-align: center;
  }
}
::v-deep .displayField label {
  font-weight: bold;
  font-size: 14px !important;
  color: $ads-primary-blue !important;
}

::v-deep .subtitle-1 {
  font-size: 20px !important;
  color: $ads-primary-blue !important;
}
::v-deep .theme--light.v-icon {
  color: $ads-primary-blue !important;
}

::v-deep .v-card__title {
  font-size: 16px !important;
}
