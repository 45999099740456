
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationFilter {
  display: flex;

  .v-avatar {
    overflow: hidden;
  }
}

::v-deep .custom {
  &.v-text-field {
    margin-left: 1rem;
    padding-top: 0;
  }

  &.v-text-field > .v-input__control > .v-input__slot {
    &::before,
    &::after {
      border-style: none;
    }
  }

  &.v-input--is-focused {
    &.v-text-field > .v-input__control > .v-input__slot {
      i.v-icon {
        border-radius: 50%;
        background-color: $grey-4;
      }
    }
  }

  .v-select__selections .text-selection-title h1 {
    font-size: 2rem;
    line-height: 1;
    color: white;
  }

  .v-select__selections .text-selection-subtitle {
    color: white !important;
  }

  .v-input__append-inner .v-icon {
    color: white;
  }
}
