
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-chip.chip.v-chip--outlined {
  border-color: $grey-darken1;
  background-color: white !important;
  color: $grey-darken1;
}
.v-chip.chip.v-chip--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
  border-color: $ads-navy;
}
.checkFilter {
  margin-top: 2px;
}
a {
  text-decoration: none;
}

::v-deep .filterOptions .theme--light.v-label {
  color: $grey-dark;
}

::v-deep .filterOptions .theme--light.v-list-item .v-list-item__subtitle {
  font-size: 16px;
  color: $grey-dark;
}

::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
}

::v-deep .v-list-item__title {
  font-size: 16px;
}
.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  a {
    text-decoration: underline;
  }
}
.column_wrapper {
  max-height: 400px;
  display: flex;
  flex-flow: column wrap;
}
