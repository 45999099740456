
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.rotate180 {
  transform: rotate(180deg);
}
.profilemenu.profilemenu {
  a {
    text-decoration: none;
  }

  .v-list-item__icon {
    margin-right: 16px;
  }
  .v-list-item__title {
    font-size: 16px;
    white-space: normal;
    text-overflow: initial;
  }
}

.user-avatar {
  cursor: pointer;
}
.profileBtn {
  border: 0;
}
.profile-menu-btn {
  border: 0;
}
.profile-menu-btn.theme--light.v-btn--disabled {
  .v-avatar.primary.lighten-3 {
    background-color: $color-btn-disabled-text-default !important;
    border-color: $color-btn-disabled-text-default !important;
  }
}
