
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.heading {
  font-size: 20px;
  padding: 10px 20px 0 0;
  color: $ads-primary-blue;
  line-height: 1.4 !important;
  display: inline;
}
.subheading {
  color: $ads-grey-01;
  font-size: 16px;
  line-height: 24px;
}
.results-template-container {
  background-color: rgba(168, 237, 179, 0.6);
  line-height: 1.5;
  padding: 26px;
  border-radius: 4px;
  margin: 40px 30px 0 30px;
  .success-numbers {
    font-size: 56px;
    font-weight: 700;
    color: $ads-success-green;
  }
}
#radio-point {
  margin-top: 16px;
}
.error-numbers {
  color: $ads-error-red;
  font-size: 16px;
  font-weight: 700;
  .error-icon {
    font-size: 27px;
    color: $ads-error-red;
  }
}
.email-template-container {
  background-color: $ads-primary-teal;
  line-height: 1.5;
  padding: 20px;
  border-radius: 4px;
  a {
    text-decoration: underline;
    color: $ads-primary-blue;
    font-weight: 400;
  }
  a:focus {
    outline: $ads-primary-blue 2px solid;
    border-radius: 2px;
  }
}

.ooa-offers-info {
  background-color: rgba(242, 242, 242, 1);
  line-height: 1.5;
  padding: 20px;
  border-radius: 4px;
}

.divider {
  margin: 28px 0px;
  color: $ads-light-40;
  border-width: 1px;
}
.actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  .theme--light.v-btn {
    padding: 20px 20px !important;
  }
}
.final-action {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.v-text-field.v-textarea {
  margin-left: 0px;
}
.icon {
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background-color: $ads-primary-teal !important;
}
.bulk-offer-modal {
  padding: 1.7rem;
  min-height: 485px;
  position: relative;

  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: inherit;
    border: none;
    &:focus {
      box-shadow: 0 0 0 2px $color-primary;
    }
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
  }
  ::v-deep {
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--text):not(
        .v-btn--outlined
      ) {
      background-color: $ads-dark-60 !important;
      color: $ads-white !important;
    }
    textarea {
      padding: 10px 3px;
    }
    .subtext {
      font-weight: 500 !important;
    }
    .v-alert__icon.v-icon {
      margin-top: 7px;
      font-size: 30px;
    }
    .offer-note {
      margin: 20px 0px;
      line-height: 1.5;
      background-color: rgba(255, 206, 153, 0.4) !important;
      box-shadow: none !important;
      border: 2px solid #fff;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .offer-note:focus {
      border: 2px solid $ads-primary-blue;
      border-radius: 4px;
    }
  }
  .theme--light.v-btn.v-btn--text:focus {
    border: 2px solid $ads-primary-blue !important;
  }
}

.only-failure-button {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.failure-message {
  position: absolute;
  top: 40%;
  left: 18%;
}

::v-deep .theme--light.v-btn.v-btn--disabled .v-icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: $ads-primary-blue !important;
}
.v-application .success {
  background-color: $ads-success-green !important;
}
.digitise-options {
  width: 100%;
  border-radius: 0.25em;
  position: relative;
  &[disabled] {
    opacity: 1;
    background: transparent;
    cursor: default;
  }
  .selectedRecordPanel {
    background-color: white;
    height: 118px;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid $ads-grey-03;
    box-sizing: border-box;
    font-size: 18px;
  }
  .isTicked {
    background-color: $ads-primary-teal !important;
    color: $ads-primary-blue;
    border: 2px solid $color-primary;
  }
  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
    background: none;
  }
  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      color: $grey-darken1;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
}
