
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#app-notifications {
  margin-bottom: 5rem;
}
.dashboard-header {
  font-size: 1.8rem;
  color: $ads-primary-blue;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
::v-deep .v-alert {
  padding: 20px;

  &:focus {
    border: none;
    outline: 3px solid $color-primary;
  }
  .v-btn--icon {
    border: 0;
  }
  .alert--clickable-action {
    margin: 0;
  }
  .v-alert__icon.v-icon {
    font-size: 32px;
    height: 32px;
    min-width: 32px;
    margin: 0 20px 0 8px;
  }
}
