
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.change-status-modal {
  padding: 2rem;
  .v-btn {
    &.red:focus {
      box-shadow: 0 0 0 4px $color-red-border;
    }
    &.green:focus {
      box-shadow: 0 0 0 4px #ccefce;
    }
    &.confirmButton {
      color: #ffffff;
    }
  }
  .v-text-field.v-textarea {
    margin-left: 0;
  }
}

.heading {
  color: $color-primary;
  font-weight: 700;
}

.body {
  margin: 3.5rem 0;
}

.close-button {
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  border: none;
  &:focus {
    box-shadow: 0 0 0 2px $color-primary;
  }
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
}
.approvalAlert {
  line-height: 24px;
  .alertIcon {
    color: $color-warning-dark;
  }
}
::v-deep .redText {
  color: #bb1237 !important;
}
::v-deep .email-checkbox-container {
  background-color: $ads-light-10;
  border-radius: 4px;
  .theme--light.v-label {
    color: $ads-dark;
  }
  .v-input__slot {
    align-items: start;
  }
  .v-input--selection-controls__input {
    height: 22px;
  }
  .v-input--selection-controls.v-input {
    margin-top: 0;
  }
}
::v-deep {
  .v-select:not(.ads-select) {
    & .v-input__control input[readonly] {
      position: unset !important;
      top: unset !important;
    }
  }
}
::v-deep .theme--light.v-input--is-disabled input::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}
