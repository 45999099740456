
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.considerations-docs-label {
  font-size: 14px;
  color: $grey-2;
}
.considerations-docs {
  background: $grey-light-10 !important;
  border-radius: 4px !important;
  min-height: 60px;
}
.considerations-docs-file {
  padding-top: 18px;
}
