
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.w-full {
  width: 100%;
}
::v-deep .alert--element:not(.in-page-alert--small) .v-alert__wrapper {
  margin: 5px 18px;
}
::v-deep .alert--element .v-alert.alert--no-border .v-alert__border {
  display: none;
}
::v-deep .alert--element.in-page-alert--small {
  .v-alert {
    margin-bottom: 0;
  }
  .v-alert__icon.v-icon {
    font-size: 22px;
    height: 22px;
    min-width: 22px;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}
.theme--light.v-icon.v-icon--link:focus {
  border: none !important;
  outline: 3px solid currentColor;
}
::v-deep .modal-alerts {
  .alertElement:focus {
    border: none !important;
    outline: 3px solid currentColor;
  }
}
