
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

//css overrides for v-autocomplete which needs to be revisited if vuetify inputs are going to be implemented in the edit page.
::v-deep {
  .v-input__control {
    background: white;
    box-shadow: 0px 0px;
    border: 1px solid $grey-4;
    min-height: 9px;
    background: $grey-7;
    height: 35px;
    min-height: 35px !important;
    font-size: $font-size-root !important;

    .v-input__slot {
      border-radius: 0;
      background: $grey-7 !important;
      box-shadow: none !important;
      min-height: 31px !important;
      height: 31px;
      input {
        border: none;
        background: $grey-7;
        padding: 0;
        outline: none;
      }
    }
  }
  .theme--light.v-icon {
    color: black;
  }
}
