
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.max-width-800 {
  max-width: 800px;
}
.app-settings__criteria-row {
  min-height: 60px;
  &:not(:last-child) {
    border-bottom: 1px solid $ads-light-20;
  }
  &.unselected {
    background: $ads-light-10;
    .v-label {
      color: $ads-dark-80;
    }
  }
}

::v-deep .app-settings__criteria-description .v-text-field.v-textarea {
  max-width: 700px;
  margin-left: 44px;
  margin-top: 5px;
  .locked & {
    margin-top: 10px;
  }
  .v-label {
    font-weight: bold;
    font-size: 1.2rem;
  }
  textarea {
    color: $ads-dark-70;
  }
}

.app-settings__other-considerations-checkbox label span {
  color: $ads-dark;
}
.criteria__label {
  font-size: 1rem;
  color: $ads-dark;
}
