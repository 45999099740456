
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

input.uppercase {
  text-transform: uppercase;
}

input::placeholder {
  text-transform: none;
}

.AppTypeAhead_options {
  background-color: white;
  color: black;
  border-radius: 0.2rem;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 10;
  padding: 0.25em 0;
  overflow-y: auto;

  div {
    padding: 0.5em 2em 0.5em 1em;
    cursor: pointer;

    &:hover {
      background-color: $color-row-hover;
    }

    &.selected {
      background-color: $color-selected-item;
    }
  }
}

.spinner {
  font-weight: bold;
  padding: 1em;
  display: none;

  &.isLoading {
    display: inline-block;
  }

  .icon {
    animation: spin 3s infinite linear;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
