
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-alert__content button {
  border: none;
  &:focus {
    border: 3px solid $ads-navy;
  }
}
