
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.modal {
  padding: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 1.333333rem;
  color: $color-primary;
}

.body {
  margin: 3.5rem 0;
}
.withdrawModal {
  .modal__close-button {
    position: absolute;
    top: 1.25rem;
    right: 0.75rem;
    border: none;
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $color-primary !important;
    }
  }
  .theme--light.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .theme--light.v-btn.primary[data-v-040d6c82]:not(.v-btn--text):not(
      .v-btn--outlined
    ):focus {
    border: none !important;
  }
}

.highlight {
  color: $color-training;
}

#app #withdraw-btn {
  height: 50px;
  padding: 0 23px;
  width: 230px;
  margin: 0 -1rem -1rem 0;
  align-self: flex-end;
}
