
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppDatePicker {
  border: $field-border;
  background-color: $color-field-background;
  margin-top: 0.25em;
  border-radius: $field-border-radius;
  select {
    border: 0;
  }
}
