
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-expansion-panel-header.sectionError {
  span i {
    color: $ads-error-red;
  }
  span {
    color: $ads-error-red;
    font-weight: bold;
  }
}
::v-deep .col {
  padding: 6px 12px;
}
::v-deep .theme--light {
  .v-expansion-panels .v-expansion-panel .subtitle-1,
  .panel--icon .v-icon {
    color: $ads-error-red !important;
  }
}
::v-deep .displayField {
  label {
    font-weight: bold;
    font-size: 14px !important;
    color: $ads-primary-blue !important;
  }
  p {
    color: $ads-grey-01;
    line-height: 24px;
  }
}

::v-deep .subtitle-1 {
  font-size: 20px !important;
  color: $ads-primary-blue !important;
}

::v-deep .v-card__title {
  font-size: 16px !important;
  color: $ads-primary-blue !important;
}

::v-deep .v-expansion-panel-content__wrap {
  color: $ads-grey-01;
}
