
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.section-title {
  font-family: $digitize-eoi-font-family;
  font-size: 18px;
  color: $ads-navy;
  font-weight: bold;
  line-height: 26px;
}
