
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ehub-prefill-notification {
  display: flex;
  margin: $content-padding 3rem;
  padding: $content-padding;
  border: 1px solid $color-primary-lighten-1;
  border-radius: $field-border-radius;
  background-color: rgba(200, 220, 240, 0.4);
}
