
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .AppModal {
  text-align: left;
  white-space: normal;

  .box {
    padding: 1rem;
    max-width: 722px;
  }

  .heading {
    font-size: 1.3333rem;
  }

  .body {
    font-size: 1.0666rem;
    line-height: 1.6rem;
    .sub-header {
      font-size: 1.25rem;
    }
  }

  .docs-item {
    background: $grey-light-10;
    border-radius: 4px;
    padding: 10px 20px !important;

    .docs-item-title {
      font-size: 1.125rem;
    }

    .docs-item-content {
      font-size: 0.875rem;
    }
  }
}

::v-deep .header {
  flex-direction: column;
}

.heading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.closeBtn {
  margin: -0.25rem -0.5rem 0 auto;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: none !important;
  }
}

.downloading-modal ::v-deep .header {
  display: flex;
  align-items: flex-start;
}
