
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.option {
  display: inline-block;
  width: 100%;
  padding: 0.5em 1em !important;
  display: flex;
  align-items: center;
  border-radius: 0.15em;
  margin: 0.5em 0 !important;
  background-color: $color-student-record-bg !important;

  &.isTicked {
    background-color: $color-selected-item !important;
  }

  .tick {
    background-color: white;
    margin-right: 0.5em;
    padding: 0.25em;
    line-height: 1;
    text-align: center;
    border-radius: 1em;
    * {
      visibility: hidden;
    }
  }
  &.isTicked .tick {
    color: $color-primary;
    background-color: white;
    * {
      visibility: visible;
    }
  }
  .optionContent {
    flex: 1;
  }
}
