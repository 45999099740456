
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-form {
  font-family: $digitize-eoi-font-family;
  border-radius: 4px;
  .v-text-field.v-input {
    max-width: 500px !important;
    margin-bottom: 10px;
  }
  .comment-field.v-text-field.v-textarea {
    margin-left: 0 !important;
  }
}
.page-title {
  font-family: $digitize-eoi-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
.section-heading {
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
  font-style: normal;
  color: $ads-navy;
}
h2 {
  color: $ads-navy;
  font-size: 1.375rem;
  line-height: 1.2;
}
::v-deep .v-text-field.v-textarea {
  max-width: 800px !important;
  margin-left: 0px !important;
  margin-top: 5px;
}
.de-emphasise {
  font-weight: 500;
}
.blue-icon {
  color: $ads-blue-1 !important;
}
.no-outline {
  border: none !important;
}
.note {
  color: $color-text-body;
}
.hint {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $color-dark70;
}
.contact-border {
  border-top: 1px solid $ads-light-20 !important;
}
.addcontact-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.remove-btn {
  &:focus {
    border: 2px solid $ads-navy !important;
  }
}
