
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-chip.chip.v-chip--outlined {
  border-color: $grey-darken1;
  background-color: white !important;
  color: $grey-darken1;
}
.v-chip.chip.v-chip--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
}
.checkFilter {
  margin-top: 2px;
}
.dialogCloseBtn {
  border: none;
}
.date-range-input__menu {
  width: 610px;

  & ::v-deep .theme--light.v-messages {
    min-height: 2.25rem;
    font-size: 16px !important;
  }
}
a {
  text-decoration: none;
}

::v-deep .filterOptions .theme--light.v-label {
  color: $color-primary;
  font-weight: 700;
}

::v-deep .v-list-item__title {
  font-size: 1.125rem;
}

.specificDatePicker,
.by-date-range {
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.by-date-range {
  &__pickers {
    $pickers-gap: 1.5rem;

    display: flex;
    gap: $pickers-gap;

    & > div {
      flex: 0 0 calc(50% - #{$pickers-gap / 2});
    }
  }
}

.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  a {
    text-decoration: underline;
  }
  text-align: right;
  margin-right: 100px;
}

::v-deep div.v-picker--date {
  button:not(.v-btn--outlined):not(:focus) {
    border: none;
  }
}

.v-card__text {
  padding: 0rem 1.5rem 0.5rem 1rem !important;
}

.v-card__title {
  font-size: 1rem !important;
  padding: 0.5rem 0.5rem 0rem 1rem !important;
  color: $ads-grey-01;

  & .v-btn {
    border: none;
  }
}
.date-range-clear {
  display: flex;
  justify-content: flex-end;

  button {
    font-weight: normal;
    text-decoration: underline;
    font-size: 1rem;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    height: initial !important;
  }
}

.v-icon.carat {
  transition: all 0.2s ease-in-out;
  opacity: 1;
  border: none;
}
.v-icon.carat[data-open] {
  rotate: 180deg;
}
