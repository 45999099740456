
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.card-heading {
  font-size: 16px;
  color: rgba(0, 0, 0, 1) !important;
  font-weight: bold;
  text-align: center;
}

.card-body {
  color: rgba(0, 0, 0, 1) !important;
  text-align: center;
}

.card-icon {
  color: #041e42;
}

.card-contents {
  font-size: 16px;
  padding: 7rem;
  width: 950px;
  margin: 0 auto;
}

.iconCircleBackground {
  border-radius: 3rem;
  width: 74px;
  height: 74px;
  background-color: $ads-light-blue;
  line-height: 3;
}

.icon {
  margin: 0 auto; // Yes, the use of "em" and "rem" is intended
  text-align: center !important;
  display: block !important;
}
