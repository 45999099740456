// SCREEN SIZE CONDITIONALS:

// NOTE: There is no "mobile" conditional as this app
// is "mobile first", so mobile styling is the default.

// This is the point at which we swap to a sidebar layout
@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin button-reset {
  margin: 0;
  border: none;
  padding: 0;
  font-weight: inherit;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

@mixin focus-outline {
  &:focus {
    box-shadow: 0 0 0 2px #fff, 0px 0 0 4px $color-focus, 0px 0 0 0 transparent;
  }
}
