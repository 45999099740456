
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ReviewExpansionPanel {
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 0.75rem 1rem 1.75rem;
  }
  &__header {
    font-family: $digitize-eoi-font-family;
    font-size: 18px;
    color: $ads-navy;
    font-weight: bold;
    line-height: 26px;

    &.sectionError {
      color: $ads-error-red;
    }
  }

  &__title {
    font-family: $digitize-eoi-font-family;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    font-size: 18px;
    color: $ads-navy;
    font-weight: bold;
    line-height: 26px;
  }

  &__actions {
    flex: 0 1 auto;
    height: 36px;
    min-width: 64px;
  }

  &__action {
    font-weight: bold;
    font-size: 16px;
    color: $ads-navy;
    line-height: 24px;
  }
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
}
