
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.dialogModal {
  padding: 2rem;
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: absolute;
    top: 1.25rem;
    right: 1.2rem;
    border: none;
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $color-primary !important;
    }
  }
  .heading {
    display: flex;
    font-weight: 700;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3333rem;
  }

  p {
    line-height: 1.5;
  }

  .body {
    margin: 1.5rem 0;
    line-height: 1.5;
  }
}
