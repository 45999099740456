
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.file {
  margin-bottom: 20px;
  .name {
    font-weight: bold;
    text-decoration: underline;
    display: inline-block;
  }
  .status {
    max-width: 230px;
    display: flex;
    &.disabled {
      color: $grey-darken1;
    }
    .message {
      display: inline-block;
    }
  }
  ::v-deep .v-icon.disabled {
    color: $grey-darken1;
  }
  ::v-deep .btn-link {
    padding-left: 0;
    font-size: 0.9rem;
    font-weight: bold;
    > .v-btn__content {
      text-decoration: underline;
    }

    &.v-btn--disabled .v-icon,
    &.v-btn--disabled {
      color: $grey-darken1 !important;
      opacity: 1;

      > .v-btn__content {
        text-decoration: none;
      }
    }
  }
}
