
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

body {
  span.no-results-found {
    color: $ads-grey-01;

    & span.no-results-found__content {
      line-height: 1.5;
    }

    & button.no-results-found__cta {
      color: $ads-primary-blue;
      text-decoration: underline;
      padding: 0;
      margin: 0;
      border: none;

      &:focus-visible {
        outline: 3px solid $ads-primary-blue-dark;
      }
    }
  }
}
