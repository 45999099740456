@import '~@nswdoe/doe-ui-core/styles/variables.scss';

// VUETIFY FONT DEFAULTS:

$body-font-family: 'Arial', sans-serif;
$font-size-root: 16px;
$line-height-root: 1.2;
$digitize-eoi-font-family: 'Montserrat', sans-serif;

// COLOURS:

$color-primary: #041e42; // NSW Education Navy
$color-primary-lighten-1: #1d428a;
$color-primary-lighten-2: #307acd;
$color-secondary: #3772b7; // NSW Education mid blue (adjusted from #407EC9 to meet AA contrast ratio)
$color-secondary-lighten-1: #6bace4;
$color-secondary-darken-1: #1a3b69;
$color-field-hover: rgba(
  0,
  0,
  0,
  0.025
); // Faint gray background color when hovering over fields
$color-selected-item: #c8dcf0;
$color-focus: #a8d6ff;
$color-light-shade: #f3f4f7;
$color-text-body: #333333;
$color-text-light: #666666;
$color-row-hover: #f7f7f7;
$color-red: #c33021;
$color-orange: #d47f00;
$color-field-background: #fafafa;
$color-warning: #fffaed;
$color-warning-dark: #dc5800;
$color-warning-border: #ffd66d;
$color-warning-background: #ffce99;
$color-error-dark: #b81237;
$color-red-background: #fef5f5;
$color-red-border: #f3b8b5;
$color-field-border: #d9d9d9;
$color-training: #b81237;
$color-success: #00a908;
$color-success-background: #edffee;
$color-info: #2e5299;
$color-info-background: #eff5fb;
$color-dark70: #4c4f55;

$color-disabled-section: #f5f5f5;
$color-placeholder: #4c4f55;
$color-student-record-bg: #f3f3f3;

//ADS colors
$grey-1: #2e2f30; //grey 1 in ADS
$grey-2: #666666; //grey 2 in ADS
$grey-3: #b3b3b3; //grey 3 in ADS
$grey-4: #d9d9d9; //grey 4 in ADS
$grey-5: #e5e5e5; //grey 5 in ADS
$grey-6: #f5f5f5; //grey 6 in ADS
$grey-7: #fafafa; //grey 7 in ADS
$grey-darken1: #6d7079;
$grey-dark: #121212;
$grey-light-10: #f4f4f7; //light 10 in ADS
$ads-selection-control-gray: #dadada;
$ads-blue-02: #146CFD; // This is from ads 3 and we can import it after the upgrade

$btn-font-sizes: (
  'x-small': 0.625rem,
  'small': 0.75rem,
  'default': 1rem,
  'large': 1rem,
  'x-large': 1rem
);
$btn-outline-border-width: 2px;

// BREAKPOINTS:

$breakpoint-desktop: 1000px; // This is the point at which we swap to a sidebar layout

// SIZES:

$content-padding: 1rem; // Default padding between content and container
$small-text: 0.9em;
$desktop-sidebar-width: 300px; // Width of the application sidebar (containing actions) in desktop mode
$save-bar-height: 4rem; // Height of save/cancel bar at bottom of screen when editing an application

// STYLES:

$field-border: 1px solid $color-field-border;
$field-border-radius: 0.2em;
$focus-outline: 3px solid $color-focus;
$focus-outline-offset: 1px;
$panel-border-radius: 0.4rem;

$color-btn-disabled-text-darker: $grey-darken1; // Disabled button text color for primary buttons
$color-btn-disabled-text-default: #a0a5ae; // Default disabled text color
$color-btn-disabled-bg-default: $color-text-body;
$color-overlay-btn-blue-focused: #538abd;


$card-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);

//vuetify globals to be aligned with ADS
$headings: (
  //.display-4
  'h1':
    (
      'size': 2rem,
      'line-height': 1.15em
    ),
  //.display-3
  'h2':
    (
      'size': 2.25rem,
      'line-height': 1.5em
    ),
  //.display-2
  'h3':
    (
      'size': 1.5625rem,
      'line-height': 1.4em
    ),
  //.display-1
  'h4':
    (
      'size': 1.125rem,
      'line-height': 1.4em
    ),
  //.headline
  'h5':
    (
      'size': 1.0625rem
    ),
  //.title
  'h6':
    (
      'size': 0.75rem
    ),
  //.subtitle-1
  'subtitle-1':
    (
      'font-size': 1rem,
      'weight': 700,
      'line-height': 1.5rem
    ),
  //.subtitle-2
  'subtitle-2':
    (
      'size': 0.8rem
    ),
  //.body-2 overide if needed
  //.body-1 overide if needed
  //caption overide if needed
  //.overline
  'overline':
    (
      'letter-spacing': 0
    ),
  //.subtitle-3 - custom style that not in veutify
  'subtitle-3':
    (
      'size': 0.6rem
    )
);

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

.subtitle-3 {
  font-size: map-deep-get($headings, 'subtitle-3', 'size');
}
