
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.label {
  padding: 0.5em 0;
}
.noRecordsMessage {
  border-bottom: 2px solid black;
  padding-bottom: 0.5em;
}
