
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.field-value {
  font-family: $digitize-eoi-font-family;
  color: $ads-navy;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  overflow-wrap: break-word;
}

.review-field--long {
  .field-value {
    white-space: pre-wrap;
    &:not(.field-value--missing) {
      font-family: $digitize-eoi-font-family;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: $ads-navy;
    }
  }
}
.field-label {
  font-family: $digitize-eoi-font-family;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: $ads-dark-70;
  overflow-wrap: break-word;
  margin-bottom: 0.25rem;
}
