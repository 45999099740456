
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.option {
  width: 100%;
  border-radius: 0.25em;
  margin: 0.25em 0;
  position: relative;

  &[disabled] {
    opacity: 1;
    background: transparent;
    cursor: default;
  }

  .studentMatchPanel {
    background-color: $grey-light-10;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .isTicked {
    background-color: $color-selected-item;
    color: black;
    border: 2px solid $color-primary;
  }

  .studentName {
    font-size: 1.375em;
    color: $color-primary;
    font-weight: 700;
  }

  .helpText {
    font-size: 0.875rem;
    color: $grey-1;
  }

  .label {
    font-size: 0.875rem;
    padding-bottom: 5px;
    color: $color-primary;
    &:nth-of-type(3) {
      // When group contains two fields (one above the other) add a gap between them
      margin-top: 0.5em;
    }
    margin-left: 0;
  }

  .value {
    font-size: 1rem;
    color: $color-primary;
  }
  .value.matched {
    color: $color-primary;
    font-weight: bold;
  }

  .stars {
    font-size: 0.8rem;
    .on {
      color: $color-primary-lighten-1;
    }
    .off {
      opacity: 0.2;
    }
  }

  .checkbox {
    font-size: 1.5rem;
    width: 1em;
    height: 1em;
    color: white;
    background-color: white;
    border-radius: 1em;
    padding: 0.2em;
  }

  &.isTicked .checkbox {
    color: $color-primary;
  }

  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
    background: none;
  }

  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      color: $grey-darken1;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
}
