
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.FieldGroupHeading {
  padding: $content-padding;

  h3 {
    font-size: 1rem;
    margin: 0;
    display: flex;

    &.showDottedLine {
      padding: 0 0 0.15em;
    }
    span {
      flex: 1;
    }
  }
}
