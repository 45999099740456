
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.WithdrawApplication {
  h1 {
    color: $color-primary;
    width: 90%;

    .withdraw-title-highlight {
      color: $color-red;
    }
  }
  .v-card {
    background: $grey-light-10;
    box-shadow: none;
    padding-left: 20px;

    ::v-deep .v-input__slot {
      align-items: initial;
    }

    ::v-deep .v-input--selection-controls__input .v-icon {
      color: $color-btn-disabled-text-default;
    }

    ::v-deep .v-input--selection-controls .v-input__slot > .v-label {
      color: $grey-dark;
    }
  }

  button.v-btn.withdraw-close-btn {
    border: none;
    &:focus {
      box-shadow: 0 0 0 2px $color-focus !important;
    }
  }
  button.v-btn.withdraw-btn {
    border: none;
    &:focus {
      box-shadow: 0 0 0 4px $color-red-border;
    }
  }
}
