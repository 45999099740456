
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.option {
  display: inline-block;
  width: 100%;
  padding: 0.5em 1em !important;
  display: flex;
  align-items: center;
  border-radius: 0.15em;
  margin: 0.5em 0 !important;
  background-color: $color-student-record-bg !important;

  &.isTicked {
    background-color: $color-selected-item !important;
  }

  .tick {
    background-color: white;
    margin-right: 0.5em;
    padding: 0.25em;
    line-height: 1;
    color: $color-secondary;
    text-align: center;
    border-radius: 1em;
    * {
      visibility: hidden;
    }
  }
  .tick.checkbox {
    border-radius: 0.2em;
  }
  &.isTicked .tick {
    color: $color-primary;
    * {
      visibility: visible;
    }
  }
  .optionContent {
    flex: 1;
  }

  &.isTextArea .optionContent {
    white-space: pre-wrap; // Ensures carriage-returns are not removed from textareas
  }

  .source span {
    font-size: 0.75rem;
    color: $color-primary;
    font-weight: bold;
    background-color: white;
    border-radius: 1em;
    padding: 0.25em 0.75em;
    margin-left: 1em;
  }
  .conflict .fieldValue {
    font-weight: bold;
  }
  &.disabled {
    .fieldValue {
      color: $grey-1;
    }
    .fieldLabel {
      color: $grey-1;
    }
  }
  .fieldLabel {
    margin-left: 0.5em;
    font-size: 0.85em;
    color: $color-text-body;
  }

  .fields {
    display: inline-block;
    &:after {
      content: ',';
      margin-right: 0.5em;
    }
    &:last-of-type:after {
      content: '';
      margin-right: 0;
    }
  }

  &.disabled {
    background-color: white;
    opacity: 1;
    color: #999999;
    border: 1px solid #eeeeee;
    .tick {
      * {
        visibility: hidden;
      }
    }
  }
}
