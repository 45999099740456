
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationSectionBar {
  display: flex;

  h2 {
    width: 100%;
    margin-bottom: 0;

    .sectionHeader {
      height: 3.5rem;
      width: 100%;
      padding: 1rem 1rem 1rem 2rem;
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      &:focus {
        outline: none;
        background: $color-row-hover;
      }

      .icon {
        vertical-align: middle;
        height: 1.5em;
        width: 1.5em;
        padding: 0.25em;
        font-size: 0.8em;
      }

      .sectionTitle {
        flex: 1;
        h2 {
          margin: 0;
        }
      }
    }
  }

  .printPreviewSectionTitle {
    padding: 1rem 1rem 0;
    width: 100%;
    h2 {
      margin: 0;
      font-size: 1.4em;
      border-bottom: 1px solid;
      padding-bottom: 0.5rem;
    }
  }

  button:hover {
    background-color: $color-field-hover;
  }
}
