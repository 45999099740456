
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#archive-filters {
  $section-padding: 2.5rem;

  padding: 0 0.5rem;
  display: grid;
  grid-template-columns:
    calc(300px + #{$section-padding * 2})
    auto;
  grid-template-rows: 1fr;
  max-width: 1440px;

  & > section {
    padding: 2.25rem 2.5rem 1.875rem;
  }

  & > section:last-of-type {
    border-left: 1px solid $ads-grey-03;

    & form {
      position: relative;
      display: grid;
      grid-template-rows: repeat(3, max-content);
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        '. . .'
        '. . .'
        'footer footer footer';
      column-gap: 1rem;

      & #archive-filters__footer {
        grid-area: footer;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .validation-one-selection {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    color: $color-error-dark;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    height: max-content;

    ::v-deep .v-icon {
      color: $color-error-dark;
    }

    &__bar {
      position: absolute;
      top: -4px;
      left: -22px;
      bottom: 30px;
      background: $color-error-dark;
      width: 5px;
    }
  }

  form[data-show-select-one-validation] .validation-one-selection {
    opacity: 1;
  }

  & .archive-filters__actions {
    display: flex;
    gap: 1rem;

    & .archive-filters__clear,
    & .archive-filters__search {
      margin: 0 !important;
      border-width: 2px !important;
      padding: 8px 16px !important;
    }
  }

  & ::v-deep .theme--light.v-messages {
    font-size: 1rem !important;
  }

  & .will-error ::v-deep .theme--light.v-messages {
    min-height: 2.25rem;
  }
}
.v-data-table {
  margin: 1rem 3rem 3rem;
}

.total-results-found {
  visibility: hidden;
  margin: 1.5rem 3rem 1rem;
  font-weight: 700;

  &[data-show] {
    visibility: visible;
  }
}

::v-deep {
  .v-select:not(.ads-select) {
    & .v-input__control input[readonly] {
      position: unset !important;
      top: unset !important;
    }
    & .v-select-others {
      color: #8c9399;
    }
    .v-chip.v-chip--outlined.v-chip.v-chip {
      background-color: $ads-primary-teal !important;
      color: $ads-primary-blue !important;
      border: 1px solid $ads-primary-blue;
    }
  }
}
.v-list.v-select-list ::v-deep .v-list-item.v-list-item--active {
  background-color: #cbedfd !important;

  & .v-list-item__content {
    color: #002664 !important;
  }
}

::v-deep tr.v-data-table__empty-wrapper > td {
  height: 24rem !important;
}

.no-data-message {
  font-size: 1rem;
  color: $ads-grey-01;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & p {
    margin: 0;
  }

  &--start {
    & .v-icon {
      color: $ads-primary-blue;
      background-color: $ads-primary-teal;
      border-radius: 100%;
      font-size: 37px;
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  &--error {
    .mdi-exclamation-thick {
      background-color: $ads-secondary-red;
      border-radius: 100%;
      padding: 1rem;

      &::before {
        background: $ads-primary-red;
        color: $ads-secondary-red;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        height: 37px;
      }
    }
  }

  &--loading {
    & svg {
      color: $ads-secondary-blue;
      width: 3.5rem;
      height: 3.5rem;
      margin-top: 1rem;
      animation: spin 1.25s infinite linear;
      @keyframes spin {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}

::v-deep .v-data-table.v-data-table {
  & thead th {
    padding-top: 20px;
    padding-bottom: 20px;
    vertical-align: top;

    & button {
      height: initial !important;
    }
  }
  & tbody tr td {
    font-size: 0.875rem;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

::v-deep .v-chip {
  display: block;
  height: 23px;

  & .v-chip__content {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
  }
}

::v-deep .theme--light.v-input--is-disabled input::placeholder,
::v-deep .theme--light.v-input--is-disabled textarea::placeholder {
  color: #8c9399 !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table {
  & > thead,
  & > tbody,
  & > tfoot {
    & > tr {
      & > td,
      & > th {
        transition: none;
      }
    }
  }
}
