
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.error-box {
  display: flex;
  align-items: center;
  background-color: $grey-7;
  padding: 1.25rem 2rem;

  p:last-child {
    margin-bottom: 0;
  }

  &__icon {
    font-size: 3.5rem;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    background-color: $ads-light-blue;
    border-radius: 50%;
    justify-content: center;
    height: 5rem;
    width: 5rem;
  }

  &__content {
    flex: 1;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 1rem;
    color: $ads-navy;
    font-weight: bold;
  }

  &__action-button {
    font-weight: 500 !important;
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}

.action-button__icon {
  display: inline;
  font-size: 1.5rem;
  text-decoration: none;
  margin-right: 0.5rem;
}
