
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationDownloadDocument {
  .v-btn {
    letter-spacing: inherit;
    text-transform: none;
    background-color: transparent;
  }

  .v-btn::before {
    background-color: transparent;
  }

  .v-btn:hover {
    border: 2px solid $color-primary;
  }
}
#app.v-application button.v-btn:not(.v-btn--round).download-btn {
  background-color: transparent;
  height: auto;
  border: 3px solid transparent;
  font-weight: 400;
}
#app.v-application button.v-btn:not(.v-btn--round).download-btn:hover {
  background-color: $color-disabled-section;
  border: 3px solid $color-primary;
}

.download-btn:focus {
  outline: 3px solid $color-primary;
}
