
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.statusBtn {
  max-width: 290px;
}

.statusText {
  font-size: 14px;
  color: $ads-grey-02;
  text-align: left;
  font-weight: 400;
}

.headerCountNumber {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 2.5rem;
  text-align: center;
  color: $ads-grey-01;
}

::v-deep .statusBtn .v-btn__content {
  justify-content: flex-start;
}

.statusBtn {
  &:focus {
    box-shadow: 0 0 0 2px $ads-navy !important;
  }
}
