
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

h4 {
  color: $color-primary;
}

.scholasticYear {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1em;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

::v-deep .v-label {
  font-size: 14px;
  color: $color-dark70;
}

::v-deep .v-input__slot {
  margin-bottom: 0;
}

.scholastic-year-error {
  color: $color-error-dark;
}
