
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.greyBorderAndIcon {
  ::v-deep .v-alert__border {
    background-color: $color-placeholder !important;
    border-color: $color-placeholder !important;
  }
  ::v-deep .v-icon {
    color: $color-placeholder !important;
  }
}
// Remove this chunk during ADS3 upgrade
::v-deep .v-alert__wrapper {
  margin: 5px 18px 0;
}
