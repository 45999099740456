
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.FieldCollectionActions {
  padding: 0 0 1rem 1rem;
  .alertIcon {
    color: $color-red;
    display: inline-block;
    margin-right: 0.25rem;
    font-size: 0.9rem;
  }
  .unmatchedErnRecord {
    display: inline-block;
    font-size: 0.9rem;
    font-weight: normal;
    vertical-align: middle;
    span {
      color: $color-red;
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
  .resolve {
    text-decoration: underline;
    color: $color-secondary;
    margin-left: 1rem;
  }
  .conflictAlert {
    padding-top: 8px;
    margin-right: 1rem;
    background-color: $grey-light-10;
  }
  ::v-deep .conflictAlert .v-btn {
    margin-left: 12px;
    text-decoration: underline;
    height: 30px;
  }

  ::v-deep .conflictAlert .buttonText {
    color: $color-primary-lighten-1;
  }
  .FieldAlert {
    color: $color-red;
    display: flex;
    padding-top: 0.3rem;
    font-size: 0.9rem;

    .icon {
      font-size: 1rem;
      &.greyed {
        color: silver;
      }
    }
    .resolutionMessage {
      display: inline-block;
      color: $color-text-body;
      font-style: italic;
      padding: 0.6em 0.25em 0 0.25em;
    }
    .tickCircle {
      padding: 0.4em 0.25em 0 0;
      div {
        color: white;
        background-color: green;
        border-radius: 1em;
        line-height: 1;
        font-size: 0.7em;
        padding: 0.5em;
        svg {
          vertical-align: -0.2em;
        }
      }
    }
    .minusCircle {
      padding: 0.4em 0.25em 0 0;
      div {
        color: white;
        background-color: gray;
        border-radius: 1em;
        line-height: 1;
        font-size: 0.7em;
        padding: 0.5em;
        svg {
          vertical-align: -0.2em;
        }
      }
    }
  }
}
