
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.school-switcher ::v-deep {
  min-width: 300px;
  max-width: 440px;
  &.v-text-field.v-text-field--solo .v-input__control {
    min-height: 38px;
  }
  .v-input__control .v-select__slot input {
    border: none;
    background-color: $color-secondary-darken-1;
    color: #fff;
  }
  .v-icon {
    color: #fff;
  }
  .v-input__append-inner {
    .v-input__icon--clear {
      background: $color-primary-lighten-2;
    }
    .v-input__icon {
      height: 20px;
      width: 20px;
      min-width: 20px;
      .mdi-close {
        color: $color-primary-lighten-1 !important;
        font-size: 13px;
      }
    }
  }
  .v-input__append-inner {
    // Hides autocomplete drop-arrow
    display: none;
  }

  .v-list-item__mask {
    // Prevents the highlighting of search text within the droplist.
    // v-autocomplete does not seem to have an option to turn this off.
    background-color: transparent !important;
    color: inherit !important;
  }

  input::placeholder {
    color: #fff !important;
    opacity: 1;
  }

  .v-autocomplete__content {
    border-radius: $panel-border-radius !important;
    margin-top: 0.2rem;
  }
  .v-list-item {
    min-height: 42px;
  }
  .v-list-item__content {
    width: 75%;
  }

  .v-list-item__action {
    width: 25%;
    text-align: left;
  }

  .v-menu__content {
    max-height: 448px;
    height: auto;
  }
}

//Stylings for training environment
.school-switcher.school-switcher--training ::v-deep {
  .v-input__control {
    & .v-select__slot input {
      background-color: #fff;
      color: $color-primary;
    }
  }

  .v-select__selection {
    color: $color-primary;
  }

  i {
    color: $color-primary;
  }

  input::placeholder {
    color: silver !important;
    opacity: 1;
  }
}

// styles for select
.school-switcher.dropdown ::v-deep {
  .v-input__slot {
    margin: 0;
  }

  .v-text-field__details {
    display: none;
  }
  .v-select__selections .v-select__selection.v-select__selection--comma {
    color: #fff;
  }
  .v-input__append-inner {
    // Show dropdown arrow for the users with multiple schools
    display: block;
  }
}

// switcher disabled style
.school-switcher.v-input--is-disabled.theme--light ::v-deep {
  .v-input__control {
    .v-icon.mdi-magnify.theme--light {
      display: none;
    }
    .v-input__slot.secondary.darken-1,
    .v-select__slot input {
      background-color: transparent !important;
    }
  }
}

// switcher focus indicator styles for school search field
.school-switcher.v-input--is-focused.theme--light ::v-deep {
  box-shadow: 0 0 0 3px $color-secondary-lighten-1 !important;
  .v-input__slot.secondary.darken-1 {
    box-shadow: none !important;
  }
}
