
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '@/scss/app.scss';
.v-application .ieWarning.sidebarMargin {
  margin-left: calc(#{$desktop-sidebar-width} + 12px) !important;
}
