
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.duplicate-eois {
  color: black;

  .duplicate-eois__warning-icon {
    color: $color-warning-dark !important;
  }

  &__heading {
    display: flex;
    align-items: baseline;
  }

  &__toggle-btn {
    border: none;
    color: currentColor;
    padding: 0 0.5rem;

    .button-text {
      text-decoration: underline;
      font-weight: bold;
    }
    &:hover::before {
      background-color: transparent;
    }

    &:focus {
      outline: 2px solid currentColor;
    }
  }

  &__warning-icon {
    margin-right: 0.5rem;
    margin-top: -0.25rem;
    align-self: center;
    color: currentColor;
  }

  &__table {
    color: $grey-1;

    td,
    th {
      min-width: 10rem;
      padding: 0.5rem 0;
    }
  }
}
