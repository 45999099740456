
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#statusSwitcher {
  max-width: 250px;
  min-width: 200px;
}
