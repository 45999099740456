
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.goToErnMessage {
  // Override Vuetify Alert styles as Rick's design uses an unsupported
  // combination of text, icon and border colours.
  margin-top: 1rem;
  color: black !important;
  background-color: #fffaed !important;
  border-color: #ffd479 !important;
  ::v-deep .v-icon {
    color: $color-primary !important;
  }
  .messageContent {
    display: flex;
    div:first-child {
      flex: 1; // Allows message to fill available width
      padding-right: 1rem;
    }

    .v-btn {
      height: 35px;
    }
  }
}

.FieldCollectionHeading {
  padding: $content-padding;

  h3 {
    font-size: 1rem;
    margin: 0;
    display: flex;
    & > span {
      flex: 1;
    }
    .linking {
      font-size: 0.9rem;
      color: black;
      border-radius: 1em;
      vertical-align: middle;
      margin-left: 0.5em;
      font-weight: normal;
    }
    .linkingText {
      display: inline-block;
      vertical-align: middle;

      span {
        text-decoration: underline;
        color: $color-secondary;
      }
    }
    .remove {
      color: #8a8a8a;
      font-size: 1rem;
      margin-left: 1rem;
      width: 1.1em;
      text-align: center;
      &:hover {
        color: white;
        background-color: $color-red;
        border-radius: 3px;
      }
    }
  }
  &.canAddRemove h3 {
    padding: 0 0 0.15em;
  }

  .tickIcon,
  .infoIcon {
    display: inline-block;
    color: white;
    background-color: green;
    font-size: 0.7rem;
    margin-right: 0.25rem;
    width: 1.5em;
    line-height: 0;
    text-align: center;
    border-radius: 10em;
    padding: 0.25em;
    vertical-align: middle;
  }
  .tickIcon {
    background-color: green;
  }
  .alertIcon {
    color: $color-red;
    display: inline-block;
    margin-right: 0.25rem;
    font-size: 0.9rem;
  }
  .infoIcon {
    background-color: $color-secondary;
  }
  .unmatchedErnRecord {
    display: inline-block;
    font-size: 0.9rem;
    margin-left: 1em;
    font-weight: normal;
    vertical-align: middle;
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.collectionItemFields.ooa {
  .FieldCollectionHeading {
    h3 {
      font-weight: normal;
    }
  }
}
