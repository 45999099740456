
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

body h1 {
  font-size: 1.65rem;
}

::v-deep div.v-card__title h3 {
  font-size: 1.5rem;
}
