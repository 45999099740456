
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ArchiveRecordPagination {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: flex-end;

  .count {
    min-width: 60px;
    font-size: 1em;
  }

  .v-btn {
    border-color: transparent;
    color: white !important;
    &:hover {
      text-decoration: none;
    }

    &:focus {
      color: white !important;
      border: 3px solid white;
    }
    &:focus::before {
      opacity: 0;
    }
  }
  .v-btn--icon.v-size--default {
    height: 32px;
    width: 32px;
  }
}
