
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationAlerts {
  padding: 1.5rem 0 1rem;
  background-color: white;
  h2 {
    padding-bottom: 0.5rem;
    margin: 0;
    border-bottom: 1px solid #a0a5ae;
    color: $color-dark70;
  }
}
.alerts {
  color: $color-primary;
  display: flex;
  padding: 1rem 0;
  .alert {
    text-decoration: underline;
  }
}
.iconLinked {
  background-color: $color-success;
}
