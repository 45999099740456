
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.content {
  border-top: 1px solid #d4d4d4;
}

.modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
  color: $grey-darken1 !important;
  &:focus {
    color: $color-primary !important;
  }
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn-outlined
  ) {
  background-color: $grey-darken1 !important;
  color: white !important;

  .v-icon {
    color: white !important;
  }
}

.fullWidth {
  width: 100%;
}

.header {
  max-width: none;

  .helpText {
    font-size: 1rem;
    color: $grey-1;
  }
}

.icon {
  background-color: $color-selected-item;
  width: 1.5em;
  line-height: 1.5em;
}

.checkbox {
  font-size: 1.5rem;
  width: 1em;
  height: 1em;
  color: white;
  background-color: white;
  border-radius: 1em;
  padding: 0.2em;
  vertical-align: middle;
  margin-right: 0.5rem;
}

.isSelected .checkbox {
  color: $color-primary;
}

.scroll {
  // If enough screen height, make list of matches scrollable
  @media (min-height: 600px) {
    overflow: auto;
    // Makes the list of matches scrollable (viewport height minus height of dialog
    // header, current student details and footer)
    max-height: calc(100vh - 260px);
    // Padding gives space so that the focus ring is not cut off.
    // Negative margin counteracts the padding so size retains the same.
    padding: 0 1em;
    margin: 0 -1em;
  }
}

// Classes for Vue "fade" transition. Transition will fade in main dialog
// content when swapping between UI steps 1 & 2. This is necessary to show
// the content has changed, otherwise it is sometimes hard to see what has happened.
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-leave-active {
  transition: none;
}

.fade-enter {
  opacity: 0;
}

.fade-leave {
  display: none;
}
::v-deep .proceed-btn.theme--light.v-btn.v-btn--disabled .v-icon {
  color: #fff !important;
}
