
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.alertsWrapper {
  position: fixed;
  left: 20px;
  bottom: 10px;
  max-width: 50vw;
  z-index: 10;
  &.mobile {
    max-width: 100vw;
    margin-right: 20px;
  }
}

::v-deep .v-alert .v-alert__wrapper {
  .snackbar--text {
    margin-bottom: 8px;
    ~ span {
      line-height: 1.3rem;
    }
  }
  .v-alert__content {
    margin-right: 35px;
  }
  .snackbarCloseButton {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
button.snackbarCloseButton.v-btn.v-btn--flat.v-btn--icon.v-btn--round.theme--light.v-size--default:focus {
  border: 2px solid $color-primary;
}
