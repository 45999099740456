
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.conflict {
  color: $color-error-dark;
}
.record-bg-color {
  border: none;
  background-color: $color-student-record-bg;
}
