
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-chip--clickable {
  border: 1px solid $color-primary;
  background: $color-selected-item !important;
}
