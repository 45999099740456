
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.FieldReadOnly {
  padding: 0 1rem !important;
  margin: 0 0 1rem 0 !important;
  vertical-align: top;
  width: 33.33%;
  display: inline-block;
  &.width66 {
    width: 66%;
  }
  &.width100 {
    width: 100%;
  }

  &.showRedBar {
    border-left: 0.3em solid $color-red;
    padding-left: 0.7em;
    margin-bottom: 1em;
    padding-bottom: 0;
  }

  // The [disabled] attribute indicates a readOnly field here
  &[disabled] {
    cursor: not-allowed;
    opacity: 1;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    cursor: pointer;
    text-decoration: inherit;
    .iconPen,
    .iconLock {
      display: inline-block;
    }
  }

  &:not([disabled]):hover {
    background-color: $color-field-hover;
  }

  .fieldLabel {
    color: $color-text-light;
    font-size: $small-text;
    // wrap long names
    display: inline-block;
    word-break: break-word; // webkit
    word-wrap: break-word; // ie11
    max-width: 100%; // ie11
  }
  .fieldContent {
    padding: 0.5em 0 0.25em;
    color: black;
    min-height: 2em; // Ensures empty values still have height
    position: relative; // Necessary for absolutely placing icon at right hand end

    // Split long lines of text over multiple lines
    overflow-wrap: break-word;
    word-wrap: break-word;

    .textarea {
      white-space: pre-wrap; // Ensures carriage-returns are not removed from textareas
    }
  }
  .iconPen,
  .iconLock {
    display: none;
    position: absolute;
    color: $color-secondary;
    right: -0.5em;
    font-size: 0.9em;
    bottom: 0.5em;
  }
  .iconLock {
    color: black;
  }
}
