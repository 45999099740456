
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.currentStudentContent {
  background-color: $color-primary;
  color: white;
  border-radius: 4px;

  .studentName {
    font-size: 1.375em;
  }

  .label {
    font-size: 0.875rem;
    padding-bottom: 5px;
    &:nth-of-type(3) {
      // When group contains two fields (one above the other) add a gap between them
      margin-top: 0.5em;
    }
    &.isLinked {
      opacity: 1;
    }
    margin-left: 0;
    color: white;
  }

  .value {
    font-size: 1rem;
    font-weight: bold;
  }
}
