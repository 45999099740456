
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.deleteButton {
  position: absolute;
  top: 10px;
  right: 12px;
  border: none;
  color: $grey-darken1 !important;
}
.deleteButton.v-btn--icon.v-size--default {
  width: 32px;
  height: 32px;
}
.deleteButton.theme--light.v-btn.v-btn--flat.v-btn--icon.v-btn--round:focus {
  color: $color-primary !important;
  border: 3px solid $color-primary;
}
