
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.humanIcon {
  border-radius: 50%;
  background: $ads-light-blue;
  color: $ads-navy;
  min-width: 46px;
  height: 46px;
}

.AmaText {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: $color-primary;
  padding-left: 11px;
}

::v-deep .v-text-field.v-textarea {
  margin-left: 0;
}

::v-deep .col-9 {
  padding: 0 12px;
}

.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
