
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.SrnMatchStatusBar {
  padding: 1rem 1rem 0 1rem;
  &.disabled {
    opacity: 0.5;
  }

  .icon {
    margin-right: 0.6em;
    width: 1.75em;
    height: 1.75em;
  }

  .iconSearch {
    background-color: #dc5800;
  }

  .iconLinked {
    background-color: $color-success;
  }

  .iconSent {
    background-color: $color-success;
  }
  .check {
    margin-left: auto;
  }
  .no-style {
    text-decoration: underline;
    border: none;
    &:before {
      background-color: transparent !important;
    }
  }
  .v-btn {
    font-weight: 700;
  }
  .edit-srn {
    text-decoration: underline;
    border: 0;
  }
  .theme--light.v-btn.v-btn--disabled.edit-srn {
    background-color: white;
    opacity: 1;
  }
}
::v-deep .y67t-alerts {
  .alertElement:focus {
    border: none !important;
    outline: 3px solid currentColor;
  }
}
::v-deep .ads-blue-02 {
  .alertElement {
    .v-alert__icon {
      color: $ads-blue-02 !important;
    }
    .v-alert__border {
      border-color: $ads-blue-02 !important;
    }
  }
}
::v-deep .grey-dark {
  .alertElement {
    .v-alert__icon {
      color: $color-dark70 !important;
    }
    .v-alert__border {
      border-color: $color-dark70 !important;
    }
  }
}
::v-deep .alert--clickable-action:focus {
  outline: 2px solid $ads-navy;
  border-radius: 3px;
}
::v-deep .alert--textsimple {
  margin-top: 5px;
}
