
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.walkup-text {
  padding-left: 5px;
  padding-top: 2px;
}
.status-active,
.status-offered,
.status-submitted,
.status-submitted-by-paper,
.status-sent-to-ern {
  font-weight: bold;
}
.status-offer-expired,
.status-declined,
.status-withdrawn,
.status-not-required,
.status-inactive,
.status-not-accepted {
  text-decoration-line: line-through;
  color: $ads-dark-60;
}
.status-icon-withdrawn,
.status-icon-declined,
.status-icon-offer-expired,
.status-icon-not-required,
.status-icon-inactive,
.status-icon-not-accepted,
.status-icon-pending {
  color: $ads-dark-60;
}
.status-pending {
  color: $ads-dark-60;
}
.status-icon-submitted.same-school {
  color: $ads-blue-02;
}
.status-icon-submitted-by-paper.same-school {
  color: $ads-success-green;
}
.status-icon-sent-to-ern.same-school {
  color: $ads-success-green;
}
.status-icon-offered.same-school {
  color: $ads-info-blue;
}
.status-icon-active.same-school {
  color: $ads-warning-orange;
}
.different-school {
  color: $ads-dark-60;
}
