
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.alertMessage {
  color: $color-error-dark;
  .v-icon {
    color: $color-error-dark;
  }
}
