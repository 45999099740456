
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.app-settings__school-caps-input {
  width: 280px;
  max-width: 415px;
  .v-label {
    font-weight: 700;
    color: $ads-navy;
  }
}

.alert--clickable-action {
  text-transform: none !important;
  border: none !important;
  padding: 0 !important;
}

.capacity-status {
  .pips {
    display: inline-block;
    .pip {
      display: inherit;
      width: 14px;
      height: 14px;
      border-radius: 7px;
      background-color: $ads-light-20;
    }
  }
  .pips .pip {
    &:nth-child(1),
    &:nth-child(2) {
      background-color: $color-primary-lighten-1;
    }
  }
  .pips.approaching,
  .pips.over {
    .pip {
      &:nth-child(3) {
        background-color: $color-primary-lighten-1;
      }
    }
  }
  .pips.over {
    .pip {
      &:nth-child(4) {
        background-color: $color-primary-lighten-1;
      }
    }
  }
  .app-settings__capacity-text {
    font-weight: 700;
  }
}

.app-settings__capture-siblings {
  .v-label {
    color: $grey-dark;
  }
}

.app-settings__scout-notif {
  background: $ads-light-10;
  padding: 16px;
  border-radius: 4px;
  width: fit-content;
}

.schoolSettingsAlert {
  max-width: 806px;
  margin-left: 24px;

  @media (min-width: 769px) {
    margin-left: 48px;
  }
}
