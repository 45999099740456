
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-btn.help-icon {
  padding: 7.5px;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.help-menu {
  .v-icon,
  .v-list-item__title {
    color: $color-primary;
  }
  .v-list-item__title {
    font-size: 1.1rem;
    letter-spacing: 0;
  }
  .v-list-item {
    &:focus {
      outline: none;
    }
  }
}
