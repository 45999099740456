
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.alert-message {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 1.2rem 0 1.2rem 1.2rem;
  border-left: 8px solid;
  border-radius: 4px;
  border-color: transparent;
  margin: 1rem;

  &--error {
    border-color: $color-training;
    background-color: $color-red-background;
  }

  &--success {
    border-color: $color-success;
    background-color: $color-success-background;
  }

  &--info {
    border-color: $color-info;
    background-color: $color-info-background;
  }

  &--warning {
    border-color: $color-warning-dark;
    background-color: $color-warning;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__body {
    display: flex;
    font-size: 1.07rem;
  }

  &__body {
    margin-right: 2rem;

    > p {
      margin: 0;
    }
  }

  &__close-btn {
    border: none;
    margin-left: auto;
  }

  &__icon {
    margin-right: 1.2rem;
  }
}
