
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// Desktop layout shows filters as a left sidebar with
// Search and Application List filling space on the right.
$sideBarWidth: 5rem;

.ApplicationListView {
  height: 100%;
  width: 100%;
}

.v-icon {
  position: absolute;
  font-size: 260px !important;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.heading,
.text {
  z-index: 2;
}

.text {
  margin: 0;
}

.heading {
  margin-bottom: 1rem;
}

@include desktop {
  .ApplicationListView,
  .ApplicationList {
    width: 100%;
    position: absolute;
  }
}
