
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#adsDialogComponent > div.v-card__title.d-flex.justify-space-between {
  margin-bottom: 0;
  color: $grey-dark !important;
}

#adsDialogComponent > div.v-card__title.d-flex.justify-space-between > div {
  font-weight: bold;
  font-size: 20px !important;
}

#adsDialogComponent > div.v-card__text.content {
  padding: 0 24px;
  margin-bottom: 0;
}

#adsDialogComponent .close-btn__wrapper .v-btn {
  border: none;

  &:focus {
    border: 2px solid $color-primary;
  }
}
