
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .float-left {
  margin-left: 24px;
}
::v-deep .application-status {
  min-width: 142px;
  .v-chip__content span,
  .v-chip__content {
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
}

::v-deep .v-chip.v-chip--outlined.v-chip.v-chip.y67t-offered,
::v-deep .v-chip.v-chip--outlined.v-chip.v-chip.ooa-offered {
  background-color: $ads-primary-blue !important;
}

.appTitle {
  font-size: 34px;
  margin-left: 19px;
  padding-top: 3px;
}

@include desktop {
  .pagination {
    flex: 1;
    margin: 4rem 6rem 0 0;
  }
}
