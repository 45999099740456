
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.record {
  display: inline-block;
  width: 100%;
  padding: 0.5em 1em;
  align-items: center;
  border-radius: 0.25em;
  margin: 0.5em 0;
  background-color: $color-student-record-bg;

  .recordField {
    font-weight: bold;
    &:after {
      content: ', ';
    }
    &:last-of-type:after {
      content: '';
    }
  }
  select {
    margin: 0.5em 0;
    background-color: white;
    border: none;
    max-width: 100%;
    width: 100%;
  }
}
