
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.clear-filters {
  &.v-btn {
    font-size: 1rem;
    color: $ads-navy;
    border: 1px solid transparent;
    &:active,
    &:focus {
      border: 2px solid $color-primary;
    }
  }
}
