
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppSessionCountdownBar {
  position: fixed;
  width: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  background-color: #ffe7a4;
  color: black;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);

  .message {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 0.5rem;
    }
  }

  > p,
  > button {
    margin: 0;
  }
}
.disableUi {
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#000, 0.5);
}
