
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.container {
  padding: 0;
  margin: 0;
  height: auto;
}
.page-title {
  font-family: $digitize-eoi-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
::v-deep .theme--light.v-icon,
::v-deep .theme--light.v-btn.textBtn .v-btn__content,
::v-deep
  .theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon,
h2,
.navy {
  color: $ads-navy;
}
::v-deep .theme--light.v-btn.textBtn {
  padding: 0;
}
.v-expansion-panel-header {
  font-size: 18px;
  color: $ads-navy;
  font-weight: bold;
}
.v-expansion-panel-header.v-expansion-panel-header--active {
  background-color: $ads-light-10;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 24px;
}
.editBtn {
  font-size: 16px;
  display: none;
  font-weight: bold;
}
::v-deep .editBtn .v-btn__content {
  font-size: 16px;
  color: $ads-navy;
}
::v-deep .v-expansion-panel.v-expansion-panel--active.v-item--active .editBtn {
  display: inline-block;
}
.selectMenu {
  width: 60%;
}
.sectionError,
::v-deep .theme--light.v-icon.sectionError {
  color: $ads-error-red;
  font-weight: bold;
}
::v-deep .v-expansion-panel--active + .v-expansion-panel,
::v-deep .v-expansion-panel--active:not(:first-child) {
  margin-top: 0;
}

// Override Vuetify styling that removes borders and corners between panels
.ReviewExpansionPanel.v-expansion-panel {
  &:not(:last-child) {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  &::after {
    opacity: 1 !important;
  }
}
hr,
::v-deep hr {
  border-top: thin solid $ads-light-20;
}
