
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.radioGroupEdit {
  margin-top: -11px;
  ::v-deep .v-input {
    legend.v-label {
      font-size: 0.937rem !important;
      flex-basis: 100%;
      margin-bottom: 4px;
      height: auto;
    }
    label.v-label {
      color: $ads-navy;
    }
  }
}
