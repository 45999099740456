
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppSpinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(87, 101, 119, 0.28); // Shades the background slightly
  color: white;
  cursor: default;
  z-index: 10000;

  .icon {
    font-size: 8rem;
    animation: spin 3s infinite linear;
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .AppSheet {
    z-index: 10001;
    margin-top: 64px;
  }
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
