
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationAlert {
  display: flex;
  width: 100%;
  font-size: 15px;
  .icon {
    margin: 0.1em 0.5em 0 0;
    color: $color-red;
  }
  .field {
    flex: 1;
  }
  .field span:last-of-type {
    text-decoration: underline;
    font-style: normal;
  }
  padding: 0.5rem 0 !important;
}
