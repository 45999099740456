// -----------------------------------------------------------------------------------
// Defines base styles for our web app. Component styles live within their components.
// -----------------------------------------------------------------------------------

* {
  box-sizing: border-box;
}

#app.v-application {
  // Sets Vuetify defaults

  .v-select:not(.ads-select) .v-input__control input[readonly] {
    // IE11 fix: Moves v-select hidden input off screen because otherwise in IE11
    // a flashing cursor can be seen through the droplist. Note: The field cannot
    // be hidden because this will break keyboard accessibility.
    position: fixed;
    top: -100px;
  }

  // Sets drop-list row highlight colours
  .theme--light.v-list-item.v-list-item--highlighted:before {
    // Sets light grey row highlight when using up/down keys
    opacity: 0.07;
    background-color: black;
  }
  .theme--light.v-list-item.v-list-item--active:before {
    // Sets light blue row highlight for currently selected item
    opacity: 0.3;
    background-color: #407ec9;
  }
  .theme--light.v-list-item.v-list-item--active.v-list-item--highlighted:before {
    // Sets slightly darker blue row highlight for currently selected item that
    // is ALSO the item navigated to using up/down keys
    opacity: 0.4;
    background-color: #407ec9;
  }
  .theme--light.v-list-item.v-list-item--highlighted {
    box-shadow: none;
  }

  button.v-btn:not(.v-btn--round):not(.v-btn--outlined):not(.v-btn--text) {
    // Sets Vuetify button defaults for standard navy button.
    // "#app.v-application" prefix necessary to ensure precedence over Vuetify styles
    // without having to resort to "!important"
    background-color: $color-primary;
    color: white;
    font-weight: bold;
    padding: 0.5em 1em;
    letter-spacing: inherit;
    text-transform: none;

    &.v-size--default {
      font-size: inherit;
    }
    &:hover {
      text-decoration: none;
    }
    &:disabled {
      opacity: 1;
    }
  }
  .v-btn--outlined {
    font-weight: bold;
    border-color: $color-primary;
  }

  // styling for setting
  .settingsIcon.circular-btn.v-btn.v-btn--flat.v-btn--icon.v-btn--round.theme--dark.v-size--default {
    border: none;
    &:before {
      background-color: transparent;
    }
    &:focus {
      box-shadow: 0 0 0 3px $color-secondary-lighten-1;
      border: none;
    }
  }

  // styling for help button
  .help-icon.mr-3.circular-btn.v-btn.v-btn--flat.v-btn--icon.v-btn--round.theme--dark.v-size--default {
    border: none;
    &:before {
      background-color: transparent;
    }
    &:focus {
      box-shadow: 0 0 0 3px $color-secondary-lighten-1;
      border: none;
    }
  }

  // styling for profile button
  .profile-menu-btn.circular-btn.v-btn.v-btn--flat.v-btn--icon.v-btn--round.theme--light.v-size--default {
    border: none;
    &:focus {
      box-shadow: 0 0 0 3px $color-secondary-lighten-1;
      border: none;
    }
  }

  // style for modal dialog close button
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    border: none;
    color: rgba(0, 0, 0, 0.54);
    height: 36px;
    width: 36px;
    &:before {
      background-color: transparent;
    }
    &:focus {
      border: 3px solid $ads-navy;
    }
  }

  .v-dialog.v-dialog--fullscreen .v-btn:focus {
    border: 2px solid $color-focus;
  }

  .v-card__title {
    font-weight: bold;
    padding: 1rem;
    font-size: 1.25rem;
  }

  .v-card__text {
    padding: 1rem;
  }

  .v-alert,
  .v-card__text {
    font-size: inherit;
  }

  // bold and navy input labels
  .v-text-field .v-label {
    padding-right: 2px;
    background-color: #ffffff;
  }

  .theme--light.v-text-field:not(.v-input--is-disabled) .v-label,
  .theme--light.v-input--radio-group:not(.v-input--is-disabled) legend {
    color: $ads-navy;
  }

  .theme--light.v-text-field .v-label,
  .theme--light.v-input--radio-group legend {
    font-weight: bold;
    font-size: 1rem;
  }

  .theme--light.v-text-field .v-label {
    font-size: calc(1rem + 1rem * 0.25);
    height: calc(2px + 1rem + 1rem * 0.25);
    line-height: calc(2px + 1rem + 1rem * 0.25);
  }

  .v-main .v-main__wrap {
    background-color: #f5f5f5;
  }
}

.forScreenReaderOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

svg.circleIcon {
  color: white;
  background-color: $color-primary;
  margin-right: 0.25em;
  width: 1em !important; // We have to override font-awesome's inline width
  text-align: center;
  border-radius: 50%;
  padding: 0.2em;
  vertical-align: middle;
}

.iconCircleBackground {
  color: white;
  font-size: 1.5em;
  background-color: $color-primary;
  width: 1.75em;
  line-height: 1.75em;
  text-align: center;
  border-radius: 1em;
  svg {
    vertical-align: -0.175em;
  }
}
.rotated90 {
  transform: rotate(90deg);
}
.text-underline {
  text-decoration: underline;
}
.font-small {
  font-size: 14px;
}

body {
  margin: 0;

  font-family: $body-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $color-text-body;
  line-height: $line-height-root;

  form.ApplicationSections {
    input::-ms-clear {
      // Hide IE "cross" icon in input fields to make the
      // experience consistent across all browsers.
      display: none;
    }

    input::placeholder {
      color: silver;
    }

    input,
    select,
    textarea,
    button {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      margin: 0;
      -webkit-appearance: none; // Prevents iOS adding unwanted field border styles
    }

    input:not([type='button']),
    select,
    textarea {
      padding: 0.5em 0.75em;
      border: $field-border;
      border-radius: $field-border-radius;
      background-color: $color-field-background;
      color: black;
    }

    input[type='date'] {
      // Date inputs can gain some additional height depending on
      // browser. The only way I can make them consistent with other
      // inputs is with this magic number  - roughly:
      //    line-height: 1.2 + 1em of padding + 2px of borders
      height: 2.313em;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:not(.v-btn):focus,
    a:focus,
    .fieldContent .v-input--is-focused // Sets focus ring for Vuetify v-autocomplete
    {
      outline: $focus-outline;
      outline-offset: $focus-outline-offset;
      position: relative; // Ensures focus outline is not covered by adjacent elements
    }

    fieldset {
      border: 0;
      padding: 0;
      margin: 0;
    }

    legend {
      font-weight: bold;
      padding: 0;
      margin: 0.5em 0;
      display: inline-block; // IE needs this to keep legend within parent
      width: 100%; // IE needs this to keep legend within parent
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; // Hides default drop button
      cursor: pointer;
      background-image: url("data:image/svg+xml,%3Csvg fill='currentColor' width='1.5em' viewBox='0 0 28 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.9 1.1L8 7.2l6.1-6.1L16 2.9l-8 8-8-8 1.9-1.8z'/%3E%3Cpath fill='none' d='M2-5.8h24v24H2v-24z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right center;
      padding-right: 2em;
      background-size: 1.5em;
    }
    select::-ms-expand {
      display: none; // Hides default drop button for IE
    }
    select[disabled] {
      cursor: default;
      background-image: none;
    }

    .panel.panel-warning {
      padding: $content-padding;
      display: flex;
      background: $color-field-background;
      border: 1px solid $color-text-body;
      border-radius: $panel-border-radius;
      color: $color-text-body;
      .panel_content {
        padding-left: 1rem;
        h3 {
          margin-top: 0;
          margin-bottom: 1rem;
        }
        p {
          margin: 0.3rem 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &.panel-warning {
        svg {
          color: $color-red;
        }
      }
    }
  }

  button:not(
      .modal__close-buttons,
      .v-expansion-panel-header,
      .no-styling,
      .v-btn--fab,
      .v-icon,
      .v-picker button,
      .v-btn--disabled,
      .stepper button
    ),
  input[type='button'] {
    // Styles for DOE primary and secondary buttons
    border: 2px solid $color-primary;
    color: $color-primary;
    font-weight: bold;
    cursor: pointer;

    &:not(.v-btn--flat):not(.v-btn--round) {
      // don't touch icon buttons
      // this affects all non-ads buttons like cancel/update in application view
      border-radius: 0.25em;
      padding: 0.5em 1em;
    }
    margin: 0.25em;

    &.primary {
      background-color: $color-primary;
      color: white;
    }
    &.unstyled {
      // Removes all button styling, for when a button needs to
      // look like a plain element.
      padding: 0;
      margin: 0;
      color: inherit;
      background-color: transparent;
      font-weight: inherit;
      border-radius: 0;
      border: 0;
      text-align: left;
    }
  }
  button[disabled],
  input[type='button'][disabled] {
    border: 2px solid transparent;
    cursor: not-allowed;
    opacity: 0.25;
  }
  button:not([disabled]):not(.unstyled):not(
      .v-btn,
      .v-expansion-panel-header,
      .v-icon
    ),
  input[type='button']:not([disabled]):not(.unstyled) {
    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.25rem;
    font-weight: normal;
    margin-bottom: 0.85em;
  }
  h3 {
    font-size: 1.1rem;
  }

  a {
    color: $color-secondary;
  }

  /*Veutify CSS overrides*/
  .container {
    padding: 0;
    margin: 0;
    height: 100%;
  }
}

//IE11 compatability fix for vuetify col-auto in Application List
_:-ms-fullscreen,
:root .ApplicationList .IE-FlexAuto .v-select__selections {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 475px;
}
//IE11 fix to vertically center the dropdown list item for applciation status filter
_:-ms-fullscreen,
:root .v-menu__content .application-status {
  padding-top: 15px;

  .v-autocomplete__content.v-menu__content {
    border-radius: 0.2em;
    border: 1px solid $grey-4;
    background-color: $grey-7;
  }
  .v-select__selections {
    font-size: 15px;
  }
}

//Allow use of a button for accessibility purposes without having
//to worry about all the default styling associated with a button
button.no-styling {
  @include button-reset;
}

.v-btn.tertiary:focus {
  box-shadow: 0 0 0 2px $ads-white;
}

// accessibility fix for vuetify custom check boxes
.v-input--checkbox .v-input--selection-controls__input:hover,
.v-input--checkbox.v-input--is-focused .v-input--selection-controls__input {
  border-radius: 50%;
  box-shadow: 0 0 0 5px $grey-6, 0 0 0 7px $color-primary;
  background: $grey-6;
  transition: box-shadow 0.2s;
}

// focus style overrides for the primary and outline buttons inside v-dialog
// for now exclude the for styles app-settings__save-button to avoid border + box-shadow together
#app.v-application .v-dialog .v-card button.v-btn {
  &:focus {
    outline: none;
    &:not(.v-btn--round) {
      &:not(.v-btn--outlined):not(.v-btn--text).primary:not(
          .app-settings__save-button
        ) {
        box-shadow: 0 0 0 3px $color-secondary;
      }
      &.v-btn--outlined {
        box-shadow: 0 0 0 2px $color-primary !important;
      }
    }
  }
}

// global dropdown selected and focus styles
#app.v-application .v-select-list .theme--light.v-list-item.v-list-item {
  border: 1px solid transparent;
  &--active {
    background: $ads-navy;
    color: white !important; // overriting the primary---text color
  }

  &--active:before {
    color: white;
    opacity: 1;
    background: transparent;
  }

  &:hover,
  &--highlighted {
    border: 1px solid $ads-navy;
  }
}

// core detail view fix after refactoring
button.unstyled {
  // Removes all button styling, for when a button needs to
  // look like a plain element.
  padding: 0;
  margin: 0;
  color: inherit;
  background-color: transparent;
  font-weight: inherit;
  border-radius: 0;
  border: 0;
  text-align: left;
}
body button.unstyled:disabled {
  opacity: 1;
  color: $grey-darken1 !important;
  svg {
    color: $grey-darken1 !important;
  }
}

// disabled button/link style
.theme--light.v-btn.v-btn--disabled,
.theme--light.v-btn.v-btn--disabled .v-icon {
  color: $grey-darken1 !important;
  opacity: 1;
}

// disabled outline button style
.theme--light.v-btn.v-btn--disabled.v-btn--outlined {
  border-color: $grey-darken1 !important;
}

// error-message color
.v-application.theme--light div.error--text,
.v-application.theme--light .error--text {
  color: $color-error-dark !important;
}

.error--text input {
  color: $color-error-dark !important;
}

// override new vuetify vertical neg padding
.row {
  margin-top: unset;
  margin-bottom: unset;
}

.row + .row {
  margin-top: unset;
}
