
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationSummary {
  padding: 1.5rem 3rem 1.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;

  .summary {
    flex: 1;
    min-width: 350px;

    h1 {
      margin-top: 0;
      font-size: 1.5rem;
      font-weight: 400;

      span {
        margin-left: 20px;
      }
    }

    .fields {
      td {
        padding-bottom: 1rem;
        vertical-align: top;
      }
      .label {
        color: $color-text-light;
        white-space: nowrap;
        &:after {
          content: ':';
        }
        padding-right: 0.5rem;
      }
      .value {
        color: black;
        padding-right: 3rem;
      }
    }
  }
}
.secondaryBtn,
.sendToErnButton {
  margin: 0;
}

::v-deep .v-btn:not(.v-btn--round).v-size--x-large {
  height: 44px;
}

::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $grey-darken1 !important;
  color: $ads-selection-control-gray !important;
  .v-icon {
    color: $ads-selection-control-gray !important;
  }
}

#app.v-application button.v-btn:not(.v-btn--round).review-btn {
  color: black;
}

#app.v-application button.v-btn.sendToErnButton:focus:before {
  opacity: 0; /* Overrides the opacity of other :focus styles on this specific button */
}
