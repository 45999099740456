
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppMessageBox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);

  .box {
    margin: 1em;
    padding: $content-padding;
    max-width: 550px;
    min-width: 350px;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
    outline: none; // Prevents focus outline on dialog itself
  }

  .cancelIcon {
    margin-left: 490px;
    &:focus {
      border: 2px solid #005fcc !important;
    }
    &:after {
      background-color: transparent;
    }
  }

  .iconCircleBackground {
    background-color: $ads-light-blue;
  }

  .body {
    display: flex;
    margin: 0.5em 2em 1.75em 0;
    color: black;
    line-height: 1.3;

    .icon {
      margin: -0.5em 0.5rem 0 0; // Yes, the use of "em" and "rem" is intended
    }

    h1,
    h2 {
      margin: 0 0 1em 0;
      padding: 0;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      font-weight: bold;
    }

    p {
      margin: 0 0 0.5em 0;
    }

    a {
      font-weight: 600;
    }
  }

  .footer {
    direction: rtl;
    text-align: right;
  }

  button {
    margin: 0 0 0 0.5em;
    border: none;
  }

  .cancelButton {
    border: none;
    padding: 0.6em 0 !important;
  }

  ul {
    margin: 0;
    padding-left: 1em;
  }

  .scrollingMessageArea {
    overflow: auto;
    max-height: calc(
      100vh - 120px
    ); // Ensures message cannot extend beyond the screen height. 120px gives room for the message box footer.
  }

  #v-btn-confirm {
    height: 39px;
  }

  #v-btn-cancel {
    &:focus {
      text-decoration: underline;
    }
  }
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.linkButton {
  color: $ads-navy;
  font-weight: 700;
  text-decoration: none;
  outline: 2px solid $ads-navy;
  border-radius: 4px;
  padding: 10px 16px;

  &:hover {
    outline: 2px solid $ads-navy;
  }
}
