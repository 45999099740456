
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.change-status-modal {
  padding: 2rem;
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .close-button {
    position: absolute;
    top: 0.15rem;
    right: 0.15rem;
    border: none;
    color: $grey-darken1;
    &:focus {
      color: $color-primary;
      box-shadow: 0 0 0 2px $color-primary;
    }
  }
  .withdraw-decline-heading {
    color: $ads-red;
  }
  ::v-deep {
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--text):not(
        .v-btn--outlined
      ) {
      background-color: $ads-dark-60 !important;
      color: $ads-white !important;
      border: none;
    }
    textarea {
      padding: 10px 3px;
    }
    .subtext {
      font-weight: 500 !important;
    }
    .offer-note {
      margin: 20px 0px;
      line-height: 1.5;
      background-color: rgba(255, 206, 153, 0.4) !important;
      box-shadow: none !important;
      border: 2px solid #fff;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .offer-note:focus {
      border: 2px solid $ads-primary-blue;
      border-radius: 4px;
    }
    .v-alert__icon.v-icon {
      margin: 7px 16px 0;
      font-size: 30px;
    }
  }
  .heading {
    font-size: 20px;
    color: $ads-navy;
    line-height: 1.4 !important;
  }
  .email-template-container {
    background-color: $ads-light-10;
    line-height: 1.5;
    padding: 16px;
    border-radius: 4px;
    a {
      text-decoration: underline;
      color: $ads-dark;
      font-weight: bold;
    }
    a:focus {
      outline: $ads-dark 2px solid;
      border-radius: 2px;
    }
  }
  .divider {
    margin: 28px 0px;
    color: $ads-light-40;
    border-width: 1px;
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    .theme--light.v-btn {
      padding: 20px 40px !important;
    }
  }
}
::v-deep {
  .v-select:not(.ads-select) {
    & .v-input__control input[readonly] {
      position: unset !important;
      top: unset !important;
    }
  }
}
::v-deep .theme--light.v-input--is-disabled input::placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}
