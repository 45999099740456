
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.list-item {
  display: flex;
  align-items: center;

  &__actions {
    display: flex;
    margin-left: auto;
  }
}

ol {
  padding-left: 0;
  list-style: none;
  counter-reset: pref-counter;
}

li {
  counter-increment: pref-counter;
  padding: 1rem 0;
  font-weight: 500;
  overflow: hidden;

  &:not(:last-child) {
    border-bottom: 1px solid $ads-light-20;
  }

  &::before {
    content: counter(pref-counter);
    background: $ads-blue-1;
    width: 2.25rem;
    min-width: 2.25rem;
    height: 2.25rem;
    font-weight: bold;
    border-radius: 50%;
    display: inline-block;
    line-height: 2.25rem;
    color: white;
    text-align: center;
    margin-right: 1rem;
  }
}

.v-icon.action__icon {
  color: $ads-navy;
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 300ms;
  transition-property: all;
}

.list-enter {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}

.list-leave-active,
.list-enter-to {
  max-height: 10rem; // larger than it should ever need to be
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.list-leave-to {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}
.no-outline {
  border: none !important;
}
.v-btn {
  &:focus {
    border: 2px solid $ads-navy !important;
  }
}
