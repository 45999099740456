
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.option {
  width: 100%;
  border-radius: 0.25em !important;
  margin: 0.25em 0 !important;
  background-color: $color-student-record-bg !important;
  position: relative;

  &[disabled] {
    opacity: 1 !important;
    background: transparent !important;
    cursor: default;
    svg {
      color: white !important;
    }
  }

  &.isTicked {
    background-color: $color-selected-item !important;
    color: black !important;
  }

  .optionContent {
    padding: 0.5em;
    display: flex;
    align-items: top;
  }

  .group1 {
    flex: 0.75;
  }
  .group2 {
    flex: 0.5;
  }
  .group3 {
    flex: 1;
  }
  .group4 {
    flex: 1;
  }
  .group1,
  .group2,
  .group3 {
    padding-right: 1em;
  }
  .label {
    font-size: 0.8em;
    color: $color-dark70;
    &:after {
      content: ':';
    }
    &:nth-of-type(3) {
      // When group contains two fields (one above the other) add a gap between them
      margin-top: 0.5em;
    }
    &.isLinked {
      opacity: 1;
    }
  }
  .value.isMatch {
    font-weight: bold;
  }

  .stars {
    position: absolute;
    top: 0.25em;
    right: 0.5em;
    font-size: 0.8rem;

    .on {
      color: #ffb500;
    }
    .off {
      opacity: 0.2;
    }
  }

  .checkbox {
    font-size: 1.5rem;
    width: 1em;
    height: 1em;
    color: white;
    background-color: white;
    border-radius: 1em;
    padding: 0.2em;
    vertical-align: middle;
    margin: 1em 0.5rem 0 0;
  }
  &.isTicked .checkbox {
    color: $color-primary;
  }
}
