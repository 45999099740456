
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .AppModal {
  text-align: left;
  white-space: normal;
  .box {
    padding: 1rem;
    max-width: 550px;
  }
  .heading {
    font-size: 1.3333rem;
    font-weight: 700;
    margin-top: -1rem;
  }
  .body {
    font-size: 1.0666rem;
    line-height: 1.6rem;
  }
}
::v-deep .header {
  flex-direction: column;
}
.heading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.closeBtn {
  margin: 0 -0.5rem 0 auto;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: none !important;
  }
}
.footer {
  text-align: right;
}
.theme--light.v-icon.v-icon--link {
  color: $color-dark70;
  &:focus {
    color: $color-primary;
    outline: 3px solid $color-primary;
    border: none;
  }
}
