
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-form {
  font-family: $digitize-eoi-font-family;
  border-radius: 4px;
  .v-text-field.v-input {
    max-width: 500px !important;
    margin-bottom: 10px;
  }
}
.white-card.school-card {
  font-family: $digitize-eoi-font-family;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 1.5rem 2.75rem;
  margin: 0 -1rem;

  &:not(:last-child) {
    border-bottom: 1px solid $ads-light-20;
  }
}
.form-card {
  font-family: $digitize-eoi-font-family;
  background: $ads-white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 2rem 0;

  & > :not(hr) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .v-divider {
    margin-top: 1rem;
    margin-bottom: 1.75rem;
  }
}
.heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  color: $ads-navy;
}
.multipleSchools {
  & ::v-deep div div div legend {
    display: none;
  }
}

.school-finder {
  & > ::v-deep *:not(.nominated-schools) {
    margin-right: 2rem;
  }

  & ::v-deep .nominated-schools li {
    padding-left: 0;
    padding-right: 2rem;
  }

  &__transition {
    margin-right: 0;
  }
}
.description {
  font-size: 1rem;
  font-weight: 500;
  color: $ads-dark;
}
.page-title {
  font-family: $digitize-eoi-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
