
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#app.v-application button.v-btn:not(.v-btn--round).review-btn {
  color: black;
}
::v-deep .ApplicationStatusChips {
  width: 9.0625rem;
  font-weight: 700;
  .v-chip__content span,
  .v-chip__content {
    width: 100%;
    text-align: center;
  }
}
.Y67TSummary {
  float: right;
  .v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
      .v-btn--outlined
    ) {
    background-color: $grey-darken1 !important;
    color: white !important;
    .v-icon {
      color: white !important;
    }
  }
}
.addressDetails {
  padding: 1rem 1rem 1rem 2rem;
  .label {
    color: $color-dark70;
    white-space: nowrap;
    padding-right: 0.5rem;
    font-size: 14px;
    margin-left: 0;
  }
  .value {
    color: $color-primary;
    padding-right: 3rem;
  }
}
.details {
  display: flex;
}
.detail {
  flex: 1;
  margin-right: 12px;
  &__label {
    color: #666666;
    white-space: nowrap;
    margin-bottom: 8px;
  }
}
::v-deep .sendToErn-btn.theme--light.v-btn.v-btn--disabled .v-icon {
  color: #fff !important;
}

.theme--light.v-btn.v-btn--outlined:focus {
  box-shadow: 0 0 0 2px $ads-navy !important;
}
