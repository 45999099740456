
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

label {
  color: $color-text-light;
  font-size: $small-text;
}
.value {
  min-height: 1rem;
}

.editIcon {
  position: absolute;
  top: 7px;
  right: 7px;
  display: none;
  &:focus {
    display: block;
    border: 2px solid $ads-navy;
  }
}

.displayField:not(.readonly) {
  position: relative;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    background-color: $ads-light-10;
    .editIcon {
      display: block;
    }
  }
}

.displayField.readonly {
  cursor: not-allowed;
  label {
    cursor: not-allowed;
  }
}

.isEditing .displayField {
  padding-top: 0px !important;
}
