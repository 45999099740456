
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.flex-basis-50 {
  flex-basis: 50%;
}
.flex-basis-1-3 {
  flex-basis: 33.3333%;
}
.flex-basis-100 {
  flex-basis: 100%;
}
.rofl--margin {
  &-2 {
    margin-top: 28px;
    &:nth-of-type(-n + 2) {
      margin-top: 0;
    }
  }
  &-3 {
    margin-top: 28px;
    &:nth-of-type(-n + 3) {
      margin-top: 0;
    }
  }
}
::v-deep {
  .displayField.readonly {
    &,
    & label {
      cursor: unset;
    }
  }
}
