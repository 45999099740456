
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.FieldHeading {
  padding: $content-padding;

  h3 {
    padding: 0;
    margin: 0;
    font-size: 1rem;
  }
}
