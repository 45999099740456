
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.FieldCollection {
  padding-bottom: 0.5rem;

  &.isCollectionConflict,
  .isRecordConflict {
    background-color: $color-red-background;
  }

  .isRecordConflict {
    margin: 0.5rem 0;
  }

  .isReadOnly {
    background-color: $color-disabled-section;
    margin: 0.5rem 0;
  }

  .columnLayout {
    display: inline-block;
    vertical-align: top;
    width: 33.33%; // Currently giving a three column layout - TODO: Make responsive
    &.width66 {
      width: 66.66%;
    }
    &.width100 {
      width: 100%;
    }
    & > * {
      width: 100%;
    }
  }
  .collectionFooter {
    padding: 0 $content-padding;
    .FieldAlert {
      padding: 0;
      padding: 0.5em 0;
    }
  }
  .add {
    color: $color-secondary;
    text-decoration: underline;
    font-size: 0.9rem;
    margin: 0.5em 0;
    .icon {
      width: 1em;
      height: 1em;
      color: white;
      background-color: $color-secondary;
      border-radius: 1em;
      padding: 0.15em;
      font-size: 1.1rem;
      vertical-align: middle;
      margin-right: 0.25em;
    }
    span {
      vertical-align: middle;
    }
  }

  .alert {
    padding: 0 1em 1em;
  }

  .collectionItemFields {
    box-shadow: 0 1px 3px #c5c5c5;
    margin: 1rem;
    .collectionItemFields {
      // Child records use a flat style
      box-shadow: none;
      border: 1px solid #e9e9e9;
    }
  }
  .collectionItemFields.ooa {
    box-shadow: none;
    margin: 0;
    width: 33.33%;
    display: inline-block;
  }
  .collectionItemFields.ooa.isReadOnly {
    background-color: transparent;
  }
  .placeholderTitle {
    font-weight: bold;
    padding: 1rem;
  }
}
.collectionItemFields.isReadOnly:is(.family, .other, .emergency):has(
    [data-unmatched-kept],
    [data-unmatched-removed]
  ) {
  background-color: white;
}
::v-deep
  .collectionItemFields.isReadOnly:is(.family, .other, .emergency):has(
    [data-unmatched-removed]
  )
  .fieldContent {
  text-decoration: line-through;
  opacity: 0.6;
}
