
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.warningMessage {
  display: flex;
}
.panel {
  padding: $content-padding;
  display: flex;
  background: $color-field-background;
  border: 1px solid $color-text-body;
  border-radius: $panel-border-radius;
  color: $color-text-body;
}
.panel_content::v-deep {
  padding-left: 1rem;
  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  p {
    margin: 0.3rem 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.infoSvg {
  color: $color-red;
}
.warningSvg {
  color: $color-warning-dark;
  height: 22px;
  width: 19px;
}
