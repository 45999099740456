
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationSaveCancel {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: white;
  text-align: right;
  padding: 0 $content-padding;
  box-shadow: 0 0 5px #a9a9a9;

  .bar {
    padding: 0.75rem 0;
  }

  button {
    margin: 0 0 0 0.5em;
    width: 7em;
    border: 0;
  }
}
