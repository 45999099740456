
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationNavBar {
  padding: 1.5rem 3rem 2.5rem 3rem;
  div.back.float-left a {
    text-decoration: none !important;
  }
  .icon {
    font-size: 1.5em;
    vertical-align: middle;
    margin-right: 0.5em;
  }
  .back,
  .print,
  .download-btn {
    display: inline-block;
    white-space: nowrap;
    span {
      vertical-align: middle;
    }
  }
  .print,
  .download-btn {
    text-align: right;
  }
}
