
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.file-upload {
  margin-top: 1rem;
  background-color: #ffffff;
  color: $color-text-body;

  ::v-deep button {
    border: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.v-dialog p {
  line-height: 1.5;
}
.file-chip {
  padding-right: 31px;

  &--removable {
    padding-right: 0;
  }
}

#fileUploadRestrictions {
  color: $ads-dark-60;
  font-size: 0.933rem;
  margin-left: 2px;
  margin-top: 10px;
}

.progress-wrapper {
  background-color: $ads-light-blue;
}

.modal__close-button.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
  border: none;
  &:focus {
    color: $color-primary !important;
    border: 2px solid $color-primary !important;
  }
}

//Vuetify components
::v-deep {
  .v-text-field .v-text-field__details {
    padding: 0px 2px;
  }
  &:not(.error--text).v-messages__wrapper .v-messages__message {
    color: $ads-dark-60;
    font-size: 0.933rem;
    line-height: 1rem;
  }

  // This CSS is to resolve the bug with the upload component in safari:
  // https://github.com/vuetifyjs/vuetify/issues/10832
  // This bug was resolved in this fix:
  // https://github.com/vuetifyjs/vuetify/commit/d5800aad7dc9e62e7d398c890b7af6580e6060ce
  // as part of v2.3.11. However, due to circumstances at the time the bug was found
  // we were unable to do a vuetify update and so are implementing the fix ourselves.
  // This should be removed once vuetify is updated to this version or higher.

  .v-file-input input[type='file'] {
    pointer-events: none;
  }

  .v-file-input .v-input__icon button {
    width: 48px;
    &:hover {
      text-decoration: none !important;
    }
  }
}

// IE11 fixes
.ie11 {
  ::v-deep .v-file-input {
    .v-input__icon button {
      // fix paper clip icon positioning
      padding: 0px;
      margin-left: -3px;
    }
    .v-text-field__slot .v-label {
      // fix label positioning
      left: -28px !important;
    }
  }
}

.overwriteModalTitle {
  font-size: 1.25rem;
}
