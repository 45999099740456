
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

$search-archive-color: $ads-primary-blue;

body {
  div.search-archive {
    background: #{$ads-tertiary-blue-dark}66;
    color: $search-archive-color;
    padding: 0.5rem;
    border-radius: 0.25rem;

    width: 100%;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: flex-start;

    & span.search-archive__content {
      flex-grow: 1;
      line-height: 1.5;
      margin-bottom: 0;
    }

    & i.v-icon {
      color: $search-archive-color;
      flex-shrink: 0;
      font-size: 2rem;
    }

    & button.search-archive__cta {
      color: $search-archive-color;
      text-decoration: underline;
      padding: 0;
      margin: 0;
      border: none;

      &:focus-visible {
        outline: 3px solid $ads-primary-blue-dark;
      }
    }
  }
}
