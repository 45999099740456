
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.modal {
  padding: 1.6rem;
}

.content {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 1.333333rem;
  color: $color-primary;
}

.body {
  margin: 1.5rem 0;
  line-height: 1.5;
}
.sendToErnModal {
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: absolute;
    top: 1.25rem;
    right: 0.75rem;
    border: none;
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $color-primary !important;
    }
  }
}

.highlight {
  color: $color-training;
}

.actions {
  button.v-btn {
    padding: 0.9rem;
  }
}

.cancel-btn {
  border: none;
  font-weight: bold;

  &:hover::before {
    background-color: transparent;
  }

  &:focus {
    outline: 1px solid currentColor;
  }
}
