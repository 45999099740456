
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-skeleton-loader__heading {
  margin: 8px !important;
  width: 80% !important;
}

.v-skeleton-loader__image {
  height: 280px;
  border-radius: 20px;
  opacity: 0.5;
}
.theme--light.v-skeleton-loader {
  background: #e4e4e6 !important;
  opacity: 0.5;
  border-radius: 20px;
}

.chart-1 {
  height: 180px;
}

.chart-2 {
  height: 200px;
}

.chart-3 {
  height: 140px;
}
