
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

button.v-btn.unlink-close-btn {
  border: none;
  &:focus {
    box-shadow: 0 0 0 2px $color-focus !important;
  }
}
button.v-btn.unlink-btn {
  border: none;
  &:focus {
    box-shadow: 0 0 0 4px $color-red-border;
  }
}
