
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.circleIcon {
  font-size: 0.85rem;
  vertical-align: -0.1em;
  margin-left: 0.5em;

  &.validated {
    background-color: green;
  }
  &.accepted {
    background-color: $color-orange;
  }
  &.unknown {
    background-color: $color-red;
  }
}
