
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ooa-status-chip::v-deep {
  .v-chip__content span {
    display: inline-block;
    text-align: center;
    min-width: 100px;
    font-weight: bold;
  }
}
