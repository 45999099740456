
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.modal {
  padding: 1.6rem 0 1.6rem 0;
}

.content {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 1.333333rem;
  color: $color-primary;
  padding-left: 1rem;
}

.body {
  margin: 1.5rem 0;
  line-height: 1.5;
}

.close-button {
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  border: none;
}

.highlight {
  color: $color-training;
}

.actions {
  button.v-btn {
    padding: 0.9rem;
  }
}

.cancel-btn {
  border: none;
  font-weight: bold;

  &:hover::before {
    background-color: transparent;
  }

  &:focus {
    outline: 1px solid currentColor;
  }
}

::v-deep div.v-data-table__wrapper table {
  .v-data-table-header tr th button {
    font-size: 1rem;
    font-weight: normal;
  }
  tbody {
    tr td {
      padding: 25px 8px 25px 1rem;
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
    }
  }
}
