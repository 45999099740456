
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.message {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 0 1.2rem 1.2rem;
  border-radius: 4px;
  border-color: transparent;
  color: $grey-dark;
  font-weight: bold;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__action {
    margin-top: 0.5rem;
    text-decoration: underline;
    font-weight: bold;
  }

  &__body {
    font-size: 1.07rem;
    margin-right: 2rem;

    > p {
      margin: 0;
    }
  }

  &__icon {
    margin-right: 1.2rem;
    align-self: start;
  }
}
