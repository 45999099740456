
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationModifiedByInfo {
  margin-right: 1rem;
  color: $color-text-body;
  font-size: 0.8rem;
  text-align: right;
}
