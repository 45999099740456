
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.address-conflict-bg {
  background-color: $grey-light-10;
  .address-conflict {
    color: $color-error-dark;
    .v-icon {
      color: $color-error-dark;
      margin-top: -3px;
    }
    .conflict-text {
      padding-top: 20px;
    }
  }
  .ready-to-sent-ern {
    color: $color-warning-dark;
    .v-icon {
      color: $color-warning-dark;
      margin-top: -3px;
    }
    .sent-ern-text {
      padding-top: 20px;
    }
  }
}
