
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.countApiErrorWrapper {
  margin-top: 7rem;
}

.alertIcon {
  display: block;
  margin: 2rem auto;
}

.errorTittle {
  font-weight: bold;
  font-size: 20px;
  color: $ads-primary-blue;
  text-align: center;
}

.errorSubtitle {
  color: $ads-primary-blue;
  text-align: center;
  margin-top: 1rem;
  font-size: 14px;
}

.refreshButton {
  display: block;
  margin: 2rem auto 10rem;
}
