
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppModal {
  select {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg fill='currentColor' width='1.5em' viewBox='0 0 28 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.9 1.1L8 7.2l6.1-6.1L16 2.9l-8 8-8-8 1.9-1.8z'/%3E%3Cpath fill='none' d='M2-5.8h24v24H2v-24z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 2em;
    background-size: 1.5em;
  }

  .country {
    width: 100%;
    margin: 0.25rem 0 1rem;
    background-color: $color-field-background;
    padding: 0.5em 0.75em;
    border: $field-border;
  }

  .address {
    width: 550px;
    margin: 0.25rem 0 1rem;
    border: $field-border;
    border-radius: $field-border-radius;
    background-color: $color-field-background;
    color: black;

    input,
    select {
      border: 0;
      width: 100%;
      background-color: transparent;
      border-radius: 0;
      padding: 0.5em 0.75em;
    }

    .TypeAhead,
    .addressLine2 {
      border-bottom: 1px solid $color-field-border;
    }

    .state {
      border: 1px solid $color-field-border;
      border-width: 0 1px;
    }
  }

  .addressLine2,
  .suburb {
    text-transform: uppercase;
  }
  input::placeholder {
    text-transform: none;
  }

  .stateSuburb {
    display: flex;
  }

  .postcodeColumn {
    width: 6em; // Wide enough for postcode value and "Postcode" placeholder text when empty
    input {
      text-align: center;
    }
  }

  .suburbColumn {
    flex: 1;
  }

  .validate {
    margin: 0;
  }

  .addressStatus {
    font-weight: bold;
    text-align: center;
    padding: 0.5em 0;
    display: inline-block;

    .circleIcon {
      font-size: 1.5rem;
    }
    span {
      vertical-align: middle;
    }

    &.validated {
      color: green;
      .circleIcon {
        background-color: green;
      }
    }
    &.accepted {
      color: $color-orange;
      .circleIcon {
        background-color: $color-orange;
      }
    }
    &.unknown {
      color: $color-red;
      .circleIcon {
        background-color: $color-red;
      }
    }
  }
}
