
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

$header-height: 64px;

.card {
  background-color: white;
}
.content,
.sideContent,
.mainContent {
  background-color: #f5f5f5;
}
.sideContent {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
}

::v-deep .theme--light.v-chip[outlined='true'] {
  border: 1px solid !important;
}

// Desktop layout shows Actions as a left sidebar
@include desktop {
  .content {
    height: 100%;
    width: 100%;
  }
  .card {
    margin: 0 3rem;
  }
  .content,
  .mainContent {
    position: absolute;
  }
  .sideContent {
    position: fixed;
    height: calc(100% - #{$header-height});
    overflow-x: hidden;
    overflow-y: auto;
    top: $header-height;
    left: 0;
    width: $desktop-sidebar-width;
    padding: 1rem 1rem 1rem 2rem;
    border-bottom: 0;
  }
  .mainContent {
    top: 0;
    left: $desktop-sidebar-width;
    width: calc(100% - #{$desktop-sidebar-width});
  }

  .isEditing .sideContent {
    padding-bottom: $save-bar-height; // Extra padding for when save bar is visible, so bottom content can be reached
  }

  .isEditing .mainContent {
    padding-bottom: $save-bar-height;
  }
  .clear {
    clear: both;
  }
}
