
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.tickIcon,
.alertIcon {
  display: inline-block;
  color: white;
  background-color: green;
  font-size: 0.7em;
  margin-right: 0.25rem;
  border-radius: 1em;
  vertical-align: middle;
  line-height: 1;
  svg {
    width: 1em;
    height: 1em;
    margin: 0.5em;
    vertical-align: middle;
  }
}
.tickIcon {
  background-color: green;
}
.alertIcon {
  background-color: $color-red;
}
::v-deep .v-alert__warning-hack > .v-alert {
  .v-alert__border {
    border-width: 0;
  }
}
