
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

/**
 * TODO: Attention VUE 3 developers
 * Delete v-input--selection-controls class styles as part of Vue 3 / ADS 3 upgrade.
 */
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.select-all {
  text-decoration: underline;
}
::v-deep .dataTable.v-data-table .v-data-table__wrapper > table > tbody {
  vertical-align: top !important;
}
.column-text {
  display: inline;
  margin-left: 3px;
}
.text-wrap-inline {
  padding-left: 1.5em;
  text-indent: -1.95em;
}
::v-deep .headerBtn .v-btn__content {
  white-space: normal !important;
  text-align: left;
  flex: initial;
}
::v-deep .v-btn:not(.v-btn--round).v-size--small {
  height: auto !important;
}
.focus-ring:focus {
  outline: 2px solid $ads-navy;
  border-radius: 3px;
}
.results-text {
  margin: 25px 30px 0 0 !important;
}
.collapse-all-button {
  margin: 13px 20px 0 0 !important;
}
::v-deep .v-ripple__container {
  width: 0 !important;
}
::v-deep .ApplicationStatusChips {
  width: 9.0625rem;
  font-weight: 700;
  .v-chip__content span,
  .v-chip__content {
    width: 100%;
    text-align: center;
  }
}
.theme--light.v-chip {
  border: 1px solid $grey-darken1;
}
.v-card__actions {
  padding: 1rem 3rem;
  background: $grey-6;
}

::v-deep .v-input.searchTxtField {
  background-color: transparent;
  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }
  input::placeholder {
    color: $color-placeholder;
  }
  input:-ms-input-placeholder,
  input::-ms-input-placeholder {
    color: $color-placeholder;
  }
  //overridding clear button styles after updating to vuetify 3.2.8
  .v-input__icon--clear button {
    padding: 0;
    border: none;
  }
  .v-input__icon--clear .theme--light.v-icon:before {
    color: white;
    background-color: $color-text-light;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }
  .v-input__icon.v-input__icon--append .theme--light.v-icon {
    color: $color-secondary;
  }
}
.alert {
  color: $color-red;
}
#date-filter {
  width: 260px;
  background-color: white;
}
// data table
::v-deep .v-data-table {
  padding: 0;
  border: 0;
  .v-data-table__expand-icon {
    color: $grey-darken1;
    margin-left: -5px;
  }
  //focus styles for expand
  .v-data-table__expand-icon:focus {
    border-radius: 50%;
    color: $ads-navy;
    outline: 3px solid $ads-navy !important;
    border: none !important;
  }
  .v-data-table__expand-icon--active {
    color: $ads-navy;
    outline: none !important;
  }
  .theme--light.v-icon:focus::after {
    opacity: 0;
  }
  .v-data-table__wrapper {
    overflow-x: hidden !important;
  }
  table {
    table-layout: fixed;
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    th[scope='col'] {
      color: $color-primary;
      font-size: 16px;
      font-weight: normal;
      padding: 20px 8px;
      vertical-align: top;
      .v-data-table-header__icon {
        color: $color-primary-lighten-1;
        margin-left: 3px;
      }
      &.active {
        font-weight: bold;
        color: $color-primary;
        .v-data-table-header__icon {
          color: $color-primary;
        }
      }
      &.select-all {
        width: 60px;
      }
      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: 90px;
      }
      &.srn {
        width: 110px;
      }
      &.select-all {
        width: 60px;
      }
    }
  }

  div.v-data-table__wrapper table tbody {
    tr:not(:last-child) td {
      border-bottom: 1px solid white;
      padding: 25px 8px;
    }
    tr:last-child td {
      border-bottom: 1px solid white;
      padding: 10px;
    }
    td {
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
    }
  }
}

@include desktop {
  .ApplicationList {
    padding: 0 3rem;
    min-height: 100%;
  }

  ::v-deep .v-data-table {
    td {
      cursor: default;
    }
    tbody tr.v-data-table__expanded__content {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      vertical-align: top;
    }
    &.pending thead.v-data-table-header th.sortable.date-received {
      padding-left: 20px;
    }

    thead.v-data-table-header th.sortable {
      padding: 20px 10px;
      &.date-received {
        width: 122px;
      }
      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: auto;
        min-width: 120px;
      }
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        padding: 20px 10px;
      }
      tr:last-child td {
        padding: 20px 10px;
      }
      tr td:first-child {
        padding-left: 20px;
      }
      tr td.status-processed {
        padding-right: 20px;
      }
    }
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
    }
  }
}
#app.v-application
  button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}
body
  button:not(
    .v-expansion-panel-header,
    .no-styling,
    .v-btn--fab,
    .v-icon,
    .v-picker button,
    .v-btn--disabled,
    .stepper button
  ),
body input[type='button'] {
  margin: 0px;
}
.filterLabel {
  color: $color-placeholder;
}
.chip-label {
  min-width: 90px;
  font-weight: bold;
}
.data-table-header {
  align: right;
}

::v-deep .mdi-magnify {
  margin-top: 3px !important;
}
::v-deep .v-data-table.dataTable .searchInput {
  padding: 16px 20px !important;
}
::v-deep
  .dataTable
  table
  thead:not(.v-data-table-header-mobile)
  th:first-child {
  padding-left: 10px !important;
}
::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
  border: 3px solid transparent;
  .v-icon {
    color: $ads-white !important;
  }
}
.paddingText {
  width: 100%;
}
.paddingText1 {
  width: 10%;
}
.paddingText2 {
  padding-top: 1px;
  width: 90%;
}
.status-offer-expired,
.status-declined,
.status-withdrawn,
.status-not-required,
.status-inactive,
.status-not-accepted {
  text-decoration-line: line-through;
  color: $ads-dark-60;
}

.status-icon-withdrawn,
.status-icon-declined,
.status-icon-offer-expired,
.status-icon-not-required,
.status-icon-inactive,
.status-icon-not-accepted,
.status-icon-pending {
  color: $ads-dark-60;
}
.status-pending {
  color: $ads-dark-60;
}

[title='Multiple'] {
  color: $ads-dark !important;
  font-weight: bold;
}
.status-icon-active {
  color: $ads-warning-orange;
}
.status-icon-offered {
  color: $ads-primary-blue;
}
.status-icon-submitted {
  color: $ads-blue-02;
}
.status-icon-submitted-by-paper,
.status-icon-sent-to-ern {
  color: $ads-success-green;
}
.row-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 5px 10px 0px;
  padding: 0px;
  width: 100%;
}
.theme--light.v-btn.v-btn--outlined:focus {
  box-shadow: 0 0 0 2px $ads-navy !important;
}
.walkups-list {
  list-style-type: none;
  color: $grey-1;
  margin-top: -20px;
  margin-left: -20px;
  img {
    border-radius: 4px;
  }
  .walkup-description {
    padding-left: 5px;
  }
  .walkup-list-item {
    float: left;
    clear: left;
  }
}
