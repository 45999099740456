
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationAlerts {
  h2 {
    padding: 0.75rem 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
