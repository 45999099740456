
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-expansion-panel-header.sectionError {
  span i {
    color: $ads-error-red;
  }
  span {
    color: $ads-error-red;
    font-weight: bold;
  }
}
.col {
  padding: 0px;
}
