
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.row {
  margin: 0;
}

.col {
  padding: 0;
}

.col:nth-child(1) {
  max-width: 60px;
}

.col:nth-child(3) {
  max-width: 100px;
}

.sre-see-number-icon {
  border-radius: 50%;
  background: $ads-blue-1;
  color: #ffffff;
  min-width: 28px;
  height: 28px;
  font-weight: 700;
  padding-top: 4px;
  text-align: center;
}

.duplicate-option {
  background: $color-error-dark;
}

.app-settings__sre-description {
  margin-left: 0 !important;

  ::v-deep .v-text-field.v-textarea {
    margin-left: 0 !important;
  }
}

.error--text {
  margin-bottom: 0.5rem !important;
}

.remove-option-button:focus,
.remove-option-button:hover {
  border: 2px solid $color-primary !important;
}
