
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.dashboard-wrapper {
  margin: 5rem auto;
  max-width: calc(1200px + 6rem);
  padding: 0 3rem;

  @media (max-width: 576px) {
    margin: 3rem 1rem;
    padding: 0;
  }
}

.noActionWrapper {
  height: 320px;
  max-width: 1200px;
  margin: 2rem auto 3rem;
  border-radius: 4px;
  background: $ads-white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-right: 3rem;
  text-align: center;
  color: $ads-primary-blue;
}

.tickIcon {
  margin: 4rem auto 2rem;
}

.noActiontittle {
  font-weight: bold;
  font-size: 1.5rem;

  @media (max-width: 576px) {
    font-size: 1rem;
  }
}

.noActionSubtitle {
  font-size: 1rem;
  margin-top: 10px;

  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
}

.dashboard-header {
  font-size: 1.8rem;
  color: $ads-primary-blue;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.dashboard-links {
  font-size: 1.3rem;
  font-weight: bold;
  color: $ads-primary-blue;
}
.dashboard-links-title {
  ::v-deep .v-icon {
    margin-right: 10px;
  }
}

.dashboard-link {
  font-size: 1rem;
  color: $ads-primary-blue;
}

.dashboard-links-margin {
  margin: 1.3rem 0;
  ::v-deep .v-icon {
    margin-right: 18px;
  }
}

.appCountCard {
  margin-bottom: 5rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media screen and (min-width: 577px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
