
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.Field {
  padding: $content-padding;
  padding-top: 0;
  display: inline-block;
  vertical-align: top;
  width: 33.33%; // Currently giving a three column layout - will make responsive later

  &.width66 {
    width: 66.66%;
  }
  &.width100 {
    width: 100%;
  }

  input,
  textarea,
  select {
    width: 100%;
  }

  textarea {
    // Allows text areas to be manually resized vertically but not horizontally
    min-width: 100%;
    max-width: 100%;
    min-height: 6em;
    max-height: 20em;
  }

  .fieldContent {
    padding-top: 0.25em;

    .openAddressIcon {
      padding: 0 0.5em;
      color: silver;
    }

    button:hover .openAddressIcon,
    button:focus .openAddressIcon {
      // Highlights the address ellipsis when hovering/focused
      color: black;
    }
  }
  .fieldLabel {
    color: $color-text-light;
    font-size: $small-text;
    font-weight: normal;
    margin: 0;
    // wrap long names
    display: inline-block;
    word-break: break-word; // webkit
    word-wrap: break-word; // ie11
    max-width: 100%; // ie11
  }

  &.showRedBar {
    border-left: 0.3em solid $color-red;
    padding-left: 0.7em;
    margin-bottom: 1em;
    padding-bottom: 0;
  }

  .address {
    display: flex;
    align-items: center;
    padding: 0.5em 0 0.5em 0.75em;
    border: $field-border;
    border-radius: $field-border-radius;
    background-color: $color-field-background;
    color: black;
    width: 100%;

    .addressText {
      flex: 1;
    }
  }
  .FieldAlert {
    color: $color-red;
    display: flex;
    padding-top: 0.3rem;
    font-size: 0.9rem;
    .icon {
      font-size: 1rem;
    }
    .alertMessage {
      display: inline-block;
      padding-left: 0.25rem;
    }
  }
}
