
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.app-settings__time-frames-input {
  width: 280px;
  max-width: 415px;
  .v-label {
    font-weight: 700;
    color: $ads-navy;
  }
}

.app-settings__preview {
  background: $ads-light-10;
  padding: 16px;
  border-radius: 4px;
  .app-settings__preview-text {
    font-style: italic;
  }
}
