
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.headerBtn {
  margin: 0;
  border: none;
  padding: 0 !important;
  font-weight: inherit;
  color: inherit;

  &:hover {
    text-decoration: none;
  }

  &:hover::before {
    opacity: 0;
  }
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th
  > button,
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: $ads-navy;
  font-weight: 400;
  font-size: 16px;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th.sortable.active
  button {
  font-weight: bold;
}

::v-deep .v-input.searchTxtField {
  background-color: transparent;
  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }
  input::placeholder {
    color: $color-placeholder;
  }
  input:-ms-input-placeholder,
  input::-ms-input-placeholder {
    color: $color-placeholder;
  }
  //overridding clear button styles after updating to vuetify 3.2.8
  .v-input__icon--clear button {
    padding: 0;
    border: none;
  }
  .v-input__icon--clear .theme--light.v-icon:before {
    color: white;
    background-color: $color-text-light;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }
  .v-input__icon.v-input__icon--append .theme--light.v-icon {
    color: $color-secondary;
  }
}
.alert {
  color: $color-red;
}
// data table
::v-deep .v-data-table {
  padding: 0;
  border: 0;

  .v-chip__content span {
    display: inline-block;
    text-align: center;
    min-width: 100px;
    font-weight: bold;
  }
  .v-data-table__wrapper {
    overflow-x: hidden !important;
  }
  table {
    table-layout: fixed;
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    th[scope='col'] {
      color: $color-primary;
      font-size: 16px;
      font-weight: normal;
      padding: 20px 8px;
      vertical-align: top;
      &:first-child {
        padding-left: 20px !important;
      }
      .v-btn:not(.v-btn--round).v-size--small {
        min-width: 0;
      }
      .v-data-table-header__icon {
        color: $color-primary-lighten-1;
        margin-left: 3px;
      }
    }
  }

  div.v-data-table__wrapper table tbody {
    tr:not(:last-child) td {
      border-bottom: 1px solid white;
      padding: 25px 8px;
    }
    tr:last-child td {
      border-bottom: 1px solid white;
      padding: 10px;
    }
    td {
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
      &:last-child {
        padding-right: 20px !important;
      }
    }
  }

  div.v-data-footer {
    font-size: 14px;
  }

  @include desktop {
    td {
      cursor: default;
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        padding: 20px 10px;
      }
      tr:last-child td {
        padding: 20px 10px;
      }
      tr td:first-child {
        padding-left: 20px;
      }
    }
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
    }
  }
}
::v-deep
  .dataTable
  table
  thead:not(.v-data-table-header-mobile)
  th:first-child {
  padding-left: 10px !important;
}
::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
  border: 3px solid transparent;
  .v-icon {
    color: $ads-white !important;
  }
}
.filterLabel {
  color: $color-placeholder;
}
.row-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 5px 10px 0px;
  padding: 0px;
  width: 100%;
}
.chip-label {
  min-width: 90px;
  font-weight: bold;
}
.search-results-number {
  float: right;
  white-space: nowrap;
}
.data-table-header {
  align: right;
}
