
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppHeader {
  ::v-deep button.v-btn.circular-btn {
    border: none;
    width: 2.8rem;
    height: 2.8rem;
    &:focus {
      box-shadow: 0 0 0 2px $color-focus;
    }
  }

  padding: 0 0 0 1rem;

  &.AppHeader--training {
    background-color: $color-training !important;
    border-color: $color-training !important;
  }

  &__pi-link {
    color: $color-training;
    background-color: #fff;
    margin-left: 2rem;
    padding: 0 1rem;
    height: 2.5rem;
    border-radius: 1.5rem;
  }

  .logoTruncate {
    width: 35px;
    overflow: hidden;
    margin-right: 1em;
  }

  h1 {
    font-weight: normal;
  }

  h2.v-toolbar__title {
    margin-bottom: 0;
  }

  .userIcon {
    margin-right: 0.5em;
    opacity: 0.5;
  }
}

::v-deep .training-dropdown {
  margin-left: 1rem;
}

.help {
  line-height: 1;
  font-size: 1.5rem;
  vertical-align: middle;
  display: inline-block;
  .icon {
    border: 1px solid;
    margin: 0;
  }
}

::v-deep .v-toolbar__content {
  width: 100%;
}

.settingsIcon {
  border: none;
}
@media only screen and (max-width: 800px) {
  .AppHeader {
    width: 650px;
  }
}
.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
