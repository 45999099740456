
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-form {
  font-family: $digitize-eoi-font-family;
  border-radius: 4px;
  .v-text-field.v-input {
    max-width: 500px !important;
    margin-bottom: 10px;
  }
  .date-picker {
    max-width: 500px !important;
  }
}
.page-title {
  font-family: $digitize-eoi-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
.section-heading {
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
  font-style: normal;
  color: $ads-navy;
}
.warning-icon {
  color: $color-warning-dark !important;
}
.warning-message {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $ads-navy;
}
