
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.alert {
  color: $color-red;
}
.oes-banner {
  align-items: center;
}
.application-filter-row {
  height: 128px;
}

::v-deep .theme--light.v-tabs-items {
  background-color: #f5f5f5 !important;
}
::v-deep .theme--light.v-tabs .v-tab,
::v-deep .theme--light.v-tabs .v-tab:not(.v-tab--active) {
  color: $ads-dark;
}
::v-deep .theme--light.v-tabs .v-tab.v-tab--active {
  font-weight: bold;
}
::v-deep .theme--light.v-tabs .v-tabs-slider-wrapper {
  height: 4px !important;
  .v-tabs-slider {
    background-color: $ads-red;
  }
}
::v-deep .theme--light.v-tabs .v-tab small {
  font-size: 100%;
}
::v-deep .v-slide-group__content {
  padding-left: 48px;
  padding-right: 48px;
}

/** Generic datatable styles */
::v-deep .v-data-footer {
  text-align: right;
  .v-search-results__row {
    background-color: $grey-6;
    height: 68px;
    padding: 1.5rem;
  }
  .value {
    color: $color-primary;
    font-weight: bold;
  }
  .link {
    outline: none;
    text-decoration: underline;
  }
  .mdi-send-outline:before {
    transform: rotate(-45deg);
  }
}
::v-deep .v-data-table.dataTable.theme--light .searchInput.v-text-field {
  /* TODO revise following important tags */
  .v-label {
    color: $ads-dark-60 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    background-color: transparent !important;
  }
  .v-input__icon.v-input__icon--clear {
    // Stop the clear icon from being hidden on blur
    opacity: 1;
  }
  &.v-input .v-icon {
    &.v-icon--link:focus {
      border: 3px solid $ads-navy;
      border-radius: 50%;
      color: $ads-navy;
    }
    &:focus::after {
      opacity: 0;
    }
  }
}
::v-deep .ApplicationFilter > .v-avatar {
  border-radius: initial;
}
