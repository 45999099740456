
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.consideration-heading {
  font-weight: bold;
}
.supporting-docs-label {
  cursor: not-allowed;
  color: $color-text-light;
  font-size: 0.9em;
}
.safe {
  background: $grey-light-10;
  border-radius: 4px;
  padding: 15px 0;
}
.downloadBtn {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 12px;
}
