
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#app.v-application button.v-btn:not(.v-btn--round) {
  &.warning-btn {
    color: #ffffff;
    &:focus {
      box-shadow: 0 0 0 4px $color-red-border;
    }
  }

  &.primary {
    &:focus {
      box-shadow: 0 0 0 4px $color-focus;
    }
  }
}
#app.v-application button.v-btn:not(.v-btn--round).review-btn {
  color: black;
}
#app.v-application button.v-btn:not(.v-btn--round).withdraw-btn {
  height: 41px;
}
.statusContainer {
  background: $ads-light-10;
}
#app.v-application .v-btn--outlined:focus {
  border-width: 2px;
}
.greyBorderAndIcon {
  ::v-deep .v-alert__border {
    background-color: $color-placeholder !important;
    border-color: $color-placeholder !important;
  }
  ::v-deep .v-icon {
    color: $color-placeholder !important;
  }
}
