
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.content {
  border-top: 1px solid #d4d4d4;
}
h3 {
  font-size: 1rem;
  margin: 1em 0 0.5em 0;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #d4d4d4;
  font-weight: normal;

  &:first-of-type {
    margin-top: 0;
  }
}
.newStudent {
  width: 100%;
  border-radius: 0.15em !important;
  background-color: $color-student-record-bg !important;
  margin: 0.25em 0 !important;
  text-align: left !important;
  height: 5em;
  padding: 0.5em !important;

  &.isSelected {
    background-color: $color-selected-item !important;
  }
}
.checkbox {
  font-size: 1.5rem;
  width: 1em;
  height: 1em;
  color: white;
  background-color: white;
  border-radius: 1em;
  padding: 0.2em;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.isSelected .checkbox {
  color: $color-primary;
}
.scroll {
  // If enough screen height, make list of matches scrollable
  @media (min-height: 600px) {
    overflow: auto;
    // Makes the list of matches scrollable (viewport height minus height of dialog
    // header, current student details and footer)
    max-height: calc(100vh - 260px);
    // Padding gives space so that the focus ring is not cut off.
    // Negative margin counteracts the padding so size retains the same.
    padding: 0 1em;
    margin: 0 -1em;
  }
}
// Classes for Vue "fade" transition. Transition will fade in main dialog
// content when swapping between UI steps 1 & 2. This is necessary to show
// the content has changed, otherwise it is sometimes hard to see what has happened.
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: none;
}
.fade-enter {
  opacity: 0;
}
.fade-leave {
  display: none;
}
fieldset.srnMatchesFieldset {
  border: none;
  legend {
    width: 100%;
  }
}
#v-btn-srn {
  height: 35px;
}
#v-btn-cancel {
  &:focus {
    text-decoration: underline;
  }
}
