
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppModal {
  outline: none; // Prevents focus outline on dialog itself
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);

  .box {
    margin: 1em;
    max-width: 600px;
    min-width: 350px;
    border-radius: 0.25em;
    background-color: #fff;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    display: flex;
    flex-shrink: 0; // Prevents header squashing when screen height is small
    font-size: 1.25rem;
    align-items: center;
    color: black;
    padding: $content-padding 1em $content-padding/2 $content-padding;
  }

  .body {
    color: black;
    overflow-y: auto;
    padding: 0 $content-padding 5px; // 5px ensures focus ring not cut off on bottom fields/buttons
    margin: $content-padding/2 0;
  }

  .footer {
    text-align: right;

    > div {
      // In the template we place the most important buttons first (e.g. 'Update' before 'Cancel')
      // However it is also nice for the most important button to be in the bottom-right, so currently
      // we swap the direction of the container to achieve this, then reinstate direction for the children.
      direction: rtl;

      > * {
        direction: ltr;
      }
    }

    button {
      margin: 0 0 0 0.5em;
      min-width: 6em;
      border: none;
    }

    padding: $content-padding/2 $content-padding $content-padding;
  }
}
