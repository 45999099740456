
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
  color: $grey-darken1 !important;
  &:focus {
    color: $color-primary !important;
  }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn-outlined
  ) {
  background-color: $grey-darken1 !important;
  color: white !important;

  .v-icon {
    color: white !important;
  }
}
.fullWidth {
  width: 100%;
}
.header {
  max-width: none;
}
.icon {
  background-color: $color-selected-item;
  width: 1.5em;
  line-height: 1.5em;
}
// Classes for Vue "fade" transition. Transition will fade in main dialog
// content when swapping between UI steps 1 & 2. This is necessary to show
// the content has changed, otherwise it is sometimes hard to see what has happened.
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: none;
}
.fade-enter {
  opacity: 0;
}
.fade-leave {
  display: none;
}
.digitise-options {
  width: 100%;
  border-radius: 0.25em;
  position: relative;

  &[disabled] {
    opacity: 1;
    background: transparent;
    cursor: default;
  }
  .selectedRecordPanel {
    background-color: white;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid $grey-darken1;
    box-sizing: border-box;
    height: 80px;
    font-size: 18px;
    color: $color-primary;
  }
  .isTicked {
    background-color: $color-selected-item;
    color: black;
    border: 2px solid $color-primary;
  }
  .helpText {
    font-size: 0.875rem;
  }
  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
    background: none;
  }
  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      color: $grey-darken1;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
}
