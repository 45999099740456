
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.saveCancel {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  background-color: white;
  text-align: right;
  box-shadow: 0 0 5px #a9a9a9;
}
