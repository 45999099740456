
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-data-table.dataTable {
  background: transparent;
  tr {
    vertical-align: top;
  }
  th {
    color: $ads-dark-80 !important;
    font-size: 0.875rem !important;
  }
  td {
    color: $ads-dark-80 !important;
    font-size: 0.875rem !important;
    padding-top: 15px;
  }
}
