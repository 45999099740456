
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.round {
  border-radius: 4px;
  margin-right: 5px;
  max-width: 35px;
}
