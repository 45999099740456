
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationAlerts {
  h2 {
    padding: 0.75rem 0;
    margin: 0;
  }

  h3 {
    color: $color-secondary;
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 1rem 0;
    font-weight: normal;
  }

  ul {
    margin: 0;
    padding-left: 0;
    list-style-position: inside;
  }

  .alerts > div {
    padding-bottom: 1rem;
  }
}

.successAlert,
.infoAlert {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem 0;
  font-weight: bold;

  .tickCircle {
    width: 1em;
    height: 1em;
    color: white;
    background-color: green;
    border-radius: 1em;
    padding: 0.2em;
    font-size: 2rem;
    vertical-align: middle;
    margin-right: 0.25em;
  }

  .exclamationCircle {
    width: 1em;
    height: 1em;
    color: $color-primary;
    padding: 0.1em;
    font-size: 2rem;
    vertical-align: middle;
    margin-right: 0.25em;
  }

  span {
    vertical-align: middle;
  }
}

.infoAlert {
  display: flex;
  align-items: center;

  svg {
    align-self: start;
  }

  span {
    color: $color-primary;
    font-weight: normal;
  }
}
