
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationSections .sectionFields {
  padding: 1rem;
}

.Y67T {
  padding: 0;
}
