
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.FieldGroup {
  &.isReadOnly {
    background-color: $color-disabled-section;
    margin: 0.5rem 0;
  }

  &.isConflict {
    background-color: $color-red-background;
    margin: 0.5rem 0;
  }

  .columnLayout {
    display: inline-block;
    vertical-align: top;
    width: 33.33%; // Currently giving a three column layout - TODO: Make responsive
    &.width66 {
      width: 66.66%;
    }
    &.width100 {
      width: 100%;
    }
    & > * {
      width: 100%;
    }
  }

  .FieldAlert {
    padding: 0 1em 1em;
  }
}
.Y67T .FieldGroup {
  box-shadow: 0 1px 3px #c5c5c5;
  margin: 1rem;
}
