
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.container.adsBanner {
  font-family: $digitize-eoi-font-family;
  margin: auto;
  width: 60%;
  .appTitle {
    font-size: 51px;
  }
  .appSubtitle {
    font-size: 22px;
    font-weight: normal;
  }
  .d-flex.align-start {
    align-items: center !important;
  }
}
.banner {
  font-family: $digitize-eoi-font-family;
}
.contentPadding {
  padding: 0 50px;
}
.container {
  padding: 50px;
  height: 100%;
}
.contentbox {
  margin: 0 auto;
  width: 70%;
}
.sideContent {
  position: relative;
  min-height: 100%;
  top: 0;
}
.stepper {
  font-family: $digitize-eoi-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  position: sticky;
  height: auto;
  top: 95px;
}
.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.normal-text {
  font-family: $digitize-eoi-font-family;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}
.normal-text-medium-size {
  font-family: $digitize-eoi-font-family;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
}
.content-img.rightSpacing {
  margin-top: -20px;
}
.alertsWrapper {
  font-family: $digitize-eoi-font-family;
  position: fixed;
  left: 20px;
  bottom: 10px;
  max-width: 50vw;
  z-index: 10;
  &.mobile {
    max-width: 100vw;
    margin-right: 20px;
  }
}
::v-deep .v-alert .v-alert__wrapper {
  .snackbar--text {
    margin-bottom: 8px;
    ~ span {
      line-height: 1.3rem;
    }
  }
  .v-alert__content {
    margin-right: 35px;
  }
}
::v-deep .alertsWrapper {
  .alertElement {
    .rounds-dismiss-button {
      color: $color-error-dark;
    }
    &:focus {
      border: none;
      outline: 3px solid $color-error-dark;
    }
    .font-weight-normal {
      font-weight: 500;
      color: $grey-dark;
      line-height: 24px;
    }
    .theme--light.v-btn.v-btn--flat.v-btn--icon.v-btn--round.v-size--default {
      border: none !important;
      &:focus {
        border: 3px solid $color-error-dark !important;
      }
    }
  }
}
