
        @import "@/scss/variables.scss";
        @import "@/scss/mixins.scss";
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-chip.chip.v-chip--outlined {
  border-color: $grey-darken1;
  background-color: white !important;
  color: $grey-darken1;
}
.v-chip.chip.v-chip--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
}
.checkFilter {
  margin-top: 0px;
}
.dialogCloseBtn {
  margin-right: 3px;
}
a {
  text-decoration: none;
}

::v-deep .filterOptions .theme--light.v-label {
  color: $color-primary;
  font-weight: 700;
}

::v-deep .v-list-item__title {
  font-size: 1.125rem;
}

.datePickerRange {
  margin-left: 45px;
  max-width: 500px !important;
}

.datePickerRangeShowYearsFilter {
  margin: 28px 26px 0;
  max-width: 500px !important;
}

.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  a {
    text-decoration: underline;
  }
}
::v-deep .filterOptions {
  .filter-by-options {
    margin-top: -18px;
    .year-list {
      padding-left: 45px !important;
      .v-label {
        font-weight: 400 !important;
        color: $grey-dark !important;
      }
    }
    .filter-by {
      .v-label {
        font-weight: 400 !important;
        color: $grey-dark !important;
      }
    }
    .disabled {
      display: none;
    }
  }
}
::v-deep div.v-picker--date {
  button:not(.v-btn--outlined):not(:focus) {
    border: none;
  }
}
::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
  border: 2px solid $ads-navy;
  background: none !important;
}
::v-deep .v-input--selection-controls__input {
  .v-input--selection-controls__ripple:before {
    opacity: 0;
  }
}
.year-list.v-list-item {
  margin-bottom: -10px !important;
}
